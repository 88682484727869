import React from 'react';

const ExternalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={"inline-block"} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5571 19.2178H4.78303V8.44372H11.7634L13.5654 6.64172H3.89151C3.39834 6.64172 3 7.04006 3 7.53324V20.1093C3 20.6025 3.39834 21.0008 3.89151 21.0008H16.4676C16.9607 21.0008 17.3591 20.6025 17.3591 20.1093V10.4354L15.5571 12.2374V19.2178Z"
                fill="#12AFB8"/>
            <path
                d="M20.1083 3H15.0441C14.551 3 14.1527 3.39831 14.1527 3.89145C14.1527 4.38459 14.551 4.78289 15.0441 4.78289H17.9271L9.54372 13.1852C9.18334 13.5456 9.18334 14.0957 9.54372 14.456C9.71442 14.6267 9.94202 14.7216 10.1696 14.7216C10.3972 14.7216 10.6248 14.6267 10.7955 14.456L19.1979 6.07264V8.95562C19.1979 9.44876 19.5962 9.84706 20.0893 9.84706C20.5825 9.84706 20.9808 9.44876 20.9808 8.95562V3.89145C20.9997 3.39831 20.6014 3 20.1083 3Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default ExternalIcon;
