import {useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {QUALYS_REPORTS, REPORTS, GENPT_REPORTS} from "./utils/constants";

//queries
export const useReports = (options = {}) => {
    const {
        pentest_asset_id = null,
        pentest_id = null,
        assetId = null,
        session_id = null,
        ...queryOptions
    } = options;
    return useQuery(REPORTS, async () => {
        const res = await axios.post(`reports/fetch`, {pentest_asset_id,pentest_id,assetId,session_id})
        return res.data;
    }, {
        ...queryOptions
    })
}
//mutations
export const useGenerateReport = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const res = await axios.post(`reports`, data)
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(REPORTS)
        }
    })
}

export const useGenerateGenPTReport = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const res = await axios.post(`reports/genpt_report`, data)
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(GENPT_REPORTS)
        }
    })
}

export const useGenerateQualysReport = () => {
    const queryClient = useQueryClient();
    return useMutation(async (data) => {
        const res = await axios.post('reports/qualys_report', data);
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(QUALYS_REPORTS);
        }
    });
}

export const useSendEmailReport = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const res = await axios.post(`reports/send_email`, data)
        return res.data;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(REPORTS)
        }
    })
}
