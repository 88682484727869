import React from 'react';

const RetestFailedIcon = () => {
    return (
        <svg className={`inline-block`} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.472 2.73602C15.2881 2.95205 16.056 3.26395 16.776 3.69602C17.496 4.12809 18.1681 4.63205 18.7681 5.23205C19.3681 5.83205 19.872 6.50412 20.3041 7.22412C20.712 7.94412 21.0481 8.71205 21.2641 9.52809C21.4801 10.3441 21.6001 11.1841 21.6001 12.0241C21.6001 12.4081 21.5762 12.8162 21.5281 13.2002C21.5281 13.2481 21.5041 13.2962 21.5041 13.3441C21.4562 13.7521 21.3601 14.1362 21.2641 14.4962C21.0481 15.3122 20.712 16.0802 20.3041 16.8002C19.872 17.5202 19.3681 18.1922 18.7681 18.7922L16.5601 16.5843C17.4722 15.6722 18.0722 14.5203 18.3362 13.2722C18.5762 12.0243 18.4562 10.7043 17.9762 9.52823C17.4962 8.35216 16.6562 7.34426 15.6001 6.62426C14.5441 5.90426 13.2722 5.54426 12.0001 5.54426L12 2.40002C12.384 2.40002 12.7679 2.424 13.176 2.47208C13.224 2.47208 13.296 2.49605 13.344 2.49605C13.7281 2.544 14.112 2.64003 14.472 2.73605L14.472 2.73602Z"
                fill="#12AFB8"/>
            <path
                d="M7.41692 16.584L5.20898 18.7919C5.80898 19.3919 6.45692 19.8959 7.20105 20.328L8.76105 17.6159C8.25695 17.328 7.80092 16.9921 7.41695 16.584L7.41692 16.584Z"
                fill="#12AFB8"/>
            <path
                d="M11.1365 18.432C10.8486 18.384 10.5605 18.336 10.2965 18.264L9.48047 21.288C9.8884 21.384 10.2965 21.4801 10.7044 21.528C11.1365 21.5759 11.5444 21.6001 11.9524 21.624V18.504C11.7125 18.4799 11.4245 18.456 11.1365 18.432L11.1365 18.432Z"
                fill="#12AFB8"/>
            <path
                d="M13.6562 18.264C14.2083 18.12 14.7362 17.904 15.2162 17.6161L16.7762 20.3282C16.0562 20.7361 15.2883 21.0721 14.4723 21.2882L13.6562 18.264Z"
                fill="#12AFB8"/>
            <path
                d="M5.73689 13.6799C5.88086 14.232 6.09689 14.7599 6.38482 15.2399L3.67289 16.7999C3.26496 16.0799 2.92892 15.312 2.71289 14.496L5.73689 13.6799Z"
                fill="#12AFB8"/>
            <path
                d="M8.76103 6.38393C9.24103 6.09599 9.76897 5.87997 10.321 5.736L9.50514 2.71204C8.6891 2.92806 7.92117 3.2641 7.20117 3.67204L8.76103 6.38393Z"
                fill="#12AFB8"/>
            <path
                d="M7.41588 7.41599C7.00794 7.82392 6.67191 8.25599 6.38381 8.75995L3.67188 7.19995C4.10394 6.47995 4.60791 5.80789 5.20791 5.20789L7.41588 7.41599Z"
                fill="#12AFB8"/>
            <path
                d="M2.47232 10.752C2.52026 10.32 2.61629 9.91204 2.71232 9.52808L5.73628 10.32C5.66423 10.584 5.61628 10.8721 5.56834 11.16C5.52039 11.448 5.52039 11.736 5.52039 12H2.40039C2.40026 11.5921 2.42436 11.16 2.47231 10.7521L2.47232 10.752Z"
                fill="#12AFB8"/>
            <path
                d="M8.75652 8.75579C8.93188 8.58043 9.2161 8.58043 9.39137 8.75579L11.5076 10.872L13.6238 8.75579C13.7992 8.58043 14.0834 8.58043 14.2587 8.75579C14.434 8.93115 14.434 9.21537 14.2587 9.39064L12.1424 11.5069L14.2587 13.6231C14.434 13.7984 14.434 14.0827 14.2587 14.2579C14.0833 14.4333 13.7991 14.4332 13.6238 14.2579L11.5076 12.1417L9.39137 14.2579C9.21601 14.4332 8.93179 14.4332 8.75652 14.2579C8.58116 14.0826 8.58116 13.7983 8.75652 13.6231L10.8727 11.5069L8.75652 9.39064C8.58116 9.21528 8.58116 8.93105 8.75652 8.75579Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default RetestFailedIcon;
