import {configureStore} from '@reduxjs/toolkit'
import auth from './reducers/auth'
import common from './reducers/common'
import customer from './reducers/admin/customer'
import dashboard from './reducers/dashboard'
import form from './reducers/form'

export default configureStore({
    reducer: {
        auth,
        common,
        customer,
        form,
        dashboard,
    },
})
