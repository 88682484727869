import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from "@mui/material";

const MuiTabs = props => {
    const { tabs = [], value = 0, onChange, className, ...otherProps } = props;
    return (
        <Tabs 
            className={className} 
            variant="scrollable" 
            scrollButtons="auto" 
            value={value}
            onChange={onChange} 
            {...otherProps}
        >
            {tabs?.map((item, index) => (
                <Tab 
                    label={item} 
                    key={index} 
                    sx={{ 
                        textTransform: 'none',
                        '&.MuiTab-root': { textTransform: 'none' }
                    }}
                />
            ))}
        </Tabs>
    );
};

MuiTabs.propTypes = {
    tabs: PropTypes.array,
    value: PropTypes.number,
    onChange: PropTypes.func,
};

export default MuiTabs;