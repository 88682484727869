import React, {memo} from 'react';
import DoneIcon from "@mui/icons-material/Done";
import styles from './styles.module.scss'

const SuccessIcon = props => {
    return (
        <DoneIcon className={styles.success}/>
    );
};

export default memo(SuccessIcon);
