import React, { useState, useRef, useEffect } from 'react';
import styles from './buttons.module.scss'; // Ensure you have this CSS module with the fade effect
import { StartNewEASM, StartNewPentest } from '../Header/Menu';

const DropdownButton = ({ components }) => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative inline-flex items-center" ref={dropdownRef}>
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="w-32 px-4 py-2 bg-primary-500 text-white rounded-md flex items-center justify-center"
      >
        <span>START</span>
        <span className="ml-2">▼</span>
      </button>
      {isDropdownOpen && (
        <ul className="absolute top-full left-0 mt-2 bg-white rounded-md shadow-lg w-56">
          {components.map((component, index) => (
            <li
              key={index}
              onClick={() => {
                setCurrentComponent(index);
                setIsDropdownOpen(false);
              }}
              className="px-2 py-1 text-primary-500 font-semibold text-base hover:bg-gray-200 cursor-pointer"
            >
              {component}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownButton;
