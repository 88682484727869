import React from 'react';
import PropTypes from 'prop-types';
import styles from './buttons.module.scss'
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from '@mui/icons-material/Done';
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';

const Button = ({
    children,
    size = "medium",
    variant = "contained",
    fullWidth,
    className,
    disabled,
    rightArrow,
    leftEditIcon,
    leftTickIcon,
    addIcon,
    filterIcon,
    downloadIcon,
    exportIcon,
    retryIcon,
    type = "submit",
    ...props
}) => {
    return (
        <button
            className={`${styles.btn} ${styles[size]} ${styles[variant]} ${fullWidth ? 'w-full' : ''} 
            ${disabled ? styles.disabled : ''}  ${className}`}
            type={type}
            disabled={disabled}
            {...props}
        >
            {leftEditIcon && <EditIcon />}
            {leftTickIcon && <DoneIcon />}
            {addIcon && <AddIcon />}
            {filterIcon && <FilterAltIcon />}
            {exportIcon && <FileDownloadIcon />}
            {retryIcon && <ReplayIcon />}
            {children}{rightArrow && <ArrowForwardIosIcon className={"p-0.5"} />}
        </button>
    );
};

Button.propTypes = {
    size: PropTypes.oneOf(["large", "medium", "small", "extra_small"]),
    variant: PropTypes.oneOf(["outlined", "contained", "text"]),
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    rightArrow: PropTypes.bool
};

export default Button;
