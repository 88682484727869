import React from 'react';
import styles from "../../Admin/Dashboard/dashboard.module.scss";
import Typography from "../../../../components/Typography";
import { get2DigitFormattedNumber } from "../../../../actions/commonActions";
import { useWelcomeBannerUserManagement } from '../../../../hooks/dashboard';
import pluralize from "pluralize";

const Status = () => {
    const { data: bannerDetails } = useWelcomeBannerUserManagement({ userType: 'customer' });
    const customerCount = bannerDetails && bannerDetails.customerCount ? bannerDetails.customerCount : 0;
    const companyCount = bannerDetails && bannerDetails.companyCount ? bannerDetails.companyCount : 0;
    return (
        <div className={`${styles.overview} h-full`}>
            <div className={styles.background}></div>
            <div className={"flex"}>
                <div className={"flex-1"}>
                    <Typography variant={"h5"}>You have <span
                        className={"text-secondary-900 font-bold"}>{get2DigitFormattedNumber(customerCount)} {pluralize('User', customerCount)}</span> from <span
                            className={"text-secondary-900 font-bold"}>{get2DigitFormattedNumber(companyCount)} {pluralize('Company', companyCount)} </span> & <span
                                className={"text-secondary-900 font-bold"}>{pluralize('Siemba Team', customerCount)} </span></Typography>
                </div>
                <div>
                </div>
            </div>
        </div>
    );
};

export default Status;
