import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SearchIcon from "../SvgIcons/SearchIcon";

const SearchInput = ({
                         onChange = () => {
                         }, searchRef, onChangeWithTimeout, placeholder
                     }) => {
    const [typingTimeOut, setTypingTimeout] = useState('')
    return (
        <div className={"border border-grey1 rounded-[10px] p-2 w-full flex overflow-hidden"}>
            <SearchIcon/>
            <input ref={searchRef} onChange={(e) => {
                onChange(e?.target?.value)
                if (typingTimeOut) clearTimeout(typingTimeOut)
                setTypingTimeout(setTimeout(() => {
                    onChangeWithTimeout?.(e?.target?.value)
                }, 500))
            }}
                   className={"inline-block align-middle flex-grow ml-4 outline-none w-full overflow-ellipsis"}
                   placeholder={placeholder}/>
        </div>
    );
};

SearchInput.propTypes = {
    onChange: PropTypes.func
};

export default SearchInput;
