import React from 'react';

const UnReadIcon = ({ className }) => {
    return (
        <svg width="23" height="23" viewBox="0 0 23 23" fill="currentColor" className={`inline-block ${className}`} xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.5" cy="11.5" r="11" stroke="#228EBC"/>
        <circle cx="11.5" cy="11.5" r="5" fill="#228EBC" stroke="#228EBC"/>
        </svg>    
    );
};

export default UnReadIcon;
