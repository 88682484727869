import React from 'react';

const DraftIcon = () => {
    return (
        <svg className={"inline-block"} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.1207 22.1369C17.6299 22.1369 18.1184 21.9347 18.4784 21.5746C18.8385 21.2146 19.0407 20.7262 19.0407 20.2169V8.05717C19.0399 7.88722 18.9723 7.72449 18.8525 7.60395L15.6526 4.40399C15.593 4.34426 15.522 4.29712 15.4439 4.26511C15.3668 4.23363 15.2841 4.2173 15.2007 4.21716H6.88094C6.37173 4.21716 5.88329 4.41939 5.52329 4.77952C5.16315 5.13952 4.96094 5.62795 4.96094 6.13716V20.2169C4.96094 20.7261 5.16317 21.2146 5.52329 21.5746C5.88329 21.9347 6.37173 22.1369 6.88094 22.1369L17.1207 22.1369ZM15.8408 6.40186L16.8558 7.41688H15.8408V6.40186ZM6.24075 20.2169V6.13717C6.24075 5.96735 6.30812 5.80463 6.42826 5.68463C6.54826 5.5645 6.71098 5.49713 6.88079 5.49713H14.5608V8.05718C14.5608 8.22686 14.6282 8.38972 14.7482 8.50971C14.8682 8.62971 15.031 8.69708 15.2007 8.69708H17.7607V20.2171C17.7607 20.3869 17.6934 20.5496 17.5732 20.6696C17.4532 20.7897 17.2905 20.8571 17.1207 20.8571H6.88093C6.71111 20.8571 6.54839 20.7898 6.42839 20.6696C6.30826 20.5496 6.24089 20.3869 6.24089 20.2171L6.24075 20.2169Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default DraftIcon;
