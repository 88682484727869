import React, { useContext } from 'react';
import ReactTable from '../../../../../../components/Table';
import FullPieChart from '../../../../../../components/Widgets/pieChart';
import { CTEMInsight1002Colors } from '../../../../../../utils/constants/asset';
import { Link } from 'react-router-dom';
import { Parser } from "html-to-react";
import { LayoutContext } from '../../../../../../Providers/LayoutProvider';
import Typography from '../../../../../../components/Typography';
import BarChartComponent from '../../../../../../components/Widgets/barChart';

const Insight2 = (props) => {
    const { description, data } = props;
    const htmlParser = new Parser();
    const { mainUrl } = useContext(LayoutContext);

    const graphData = data?.graphData.map((data) => {
        return {
            name: data?.assetCriticality,
            value: Number(data?.assetCount),
            refKey: true
        }
    });

    const columns = [
        {
            Header: 'Asset Name',
            accessor: 'assetName',
            disableSortBy: true,
            Cell: props => {
                const [assetName, assetId, platform, pentestAssetId, pentestCount] = props?.value;
                return (
                    platform === 'it_infra' ?
                        <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_va/${assetId}/dashboard/`}>
                            {assetName}
                        </Link>
                    :
                        pentestCount > 1 ?
                            <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_assets/?assetId=${assetId}`}>
                                {assetName}
                            </Link>
                        :
                            <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_assets/${pentestAssetId}/dashboard`}>
                                {assetName}
                            </Link>
                )
            }
        },
        {
            Header: 'Platform',
            accessor: 'type',
            Cell: props => {
                const type = props?.value;
                return (
                    type === 'it_infra' ?
                        <span className="text-camel-case">IT Infrastructure</span>
                    : 

                        <span className="text-camel-case">{type?.replace("_", " ")}</span>
                )
            },
        },
        {
            Header: 'Vulnerability count',
            accessor: 'count',
            disableSortBy: true
        },
    ];

    const tableData = data?.data?.map((item, index) => {
        return ({
            assetName: [item?.assetName, item?.assetId, item?.type, item?.pentestAssetId, item?.pentestCount],
            type: item?.type,
            count: item?.count
        })
    })

    return (
        <div className='insight-container insight1002 flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph'>
                    {
                        data?.graphData &&
                            <BarChartComponent 
                                className={"flex flex-row h-[250px] insight2-bar-chart"}
                                labelHeight="h-[50px]"
                                graphData={graphData || []} 
                                colors={CTEMInsight1002Colors}
                            />
                    }
                </div>
            </div>
            <div className='lower-section'>
                <div>
                    {
                        data?.data &&
                            <ReactTable
                                columns={columns}
                                data={tableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight2;