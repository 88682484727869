const setData = (name, value, session = false) => {
    if (session)
        sessionStorage.setItem(name, value)
    else
        localStorage.setItem(name, value)
}

const getData = (name, session = false) => {
    if (session)
        return sessionStorage.getItem(name)
    return localStorage.getItem(name)
}
const removeData = (name, session = false) => {
    if (session)
        sessionStorage.removeItem(name)
    else
        localStorage.removeItem(name)
}

const setToken = (token) => {
    setData("jwt.siemba", token)
}

const setEasm = (value) => {
    setData("easm_enabled", value)
}


const clear = (session = false) => {
    if (session)
        sessionStorage.clear()
    else
        localStorage.clear()
}

const Storage = {
    setData,
    getData,
    token: getData("jwt.siemba"),
    setToken,
    clear,
    removeData,
    setEasm
}

export default Storage;
