import axios from "axios";
import { logout } from "../../actions/authActions";

export const validateLogin = async () => {
    let res = await axios.get('/auth/validate_login')
    return res.data;
}


axios.interceptors.response.use(
    response => {
        return response
    },
    function (error) {
        if  (error?.response?.status === 440) {
            logout();
        }        
        return Promise.reject(error)
    }
)