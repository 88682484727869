import React from 'react';
import { Parser } from "html-to-react";
import StackedBar from '../../../../../../components/Widgets/StackedBarChart';
import { CTEMInsight1003Colors } from '../../../../../../utils/constants/asset';
import Typography from '../../../../../../components/Typography';

const Insight3 = (props) => {
    const { data } = props;
    const htmlParser = new Parser();

    const graphData = [];
    data?.graphData?.map((graph) => {
        graphData.push({
            name: graph?.severity,
            pentestCount: graph?.pentestCount,
            vaCount: graph?.vaCount
        });
    });

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph stackedbar-container'>
                    <StackedBar 
                        type='insight3'
                        graphData={graphData} 
                        colors={CTEMInsight1003Colors}
                    />
                </div>
            </div>
        </div>
    );
};

export default Insight3;