import React, { useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ReactTable from '../../../../components/Table';
import Modal from '../../../../components/MuiComponents/Modal';
import Typography from '../../../../components/Typography';
import { closeAssetListModal } from '../../../../redux/reducers/dashboard';
import { useCTEMAssetList } from '../../../../hooks/ctem';
import Button from '../../../../components/Button';
import { get2DigitFormattedNumber } from '../../../../actions/commonActions';
import { Link } from 'react-router-dom';
import { LayoutContext } from '../../../../Providers/LayoutProvider';
import MainLoader from '../../../../components/Loader/MainLoader';

const AssetListModal = () => {
    const { assetListModal: { companyId, title } } = useSelector(state => state.dashboard);
    const { data, isFetching } = useCTEMAssetList(companyId);
    const assetList = data && JSON.parse(data?.assetList);

    const { mainUrl } = useContext(LayoutContext)
    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeAssetListModal())
    }

    const columns = [
        {
            Header: '#',
            accessor: 'index',
            className: 'w-14',
            disableSortBy: true,
        },
        {
            Header: 'Asset',
            accessor: 'asset',
            disableSortBy: true,
            Cell: props => {
                const [assetName, assetId, platform] = props?.value;
                return (
                    platform === 'it_infra' ?
                        <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_va/${assetId}/dashboard/`}>
                            {assetName}
                        </Link>
                    :
                        <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_assets/${assetId}/dashboard`}>
                            {assetName}
                        </Link>
                )
            }
        },
        {
            Header: 'Pentest',
            accessor: 'pentest',
            disableSortBy: false,
        },
        {
            Header: 'Platform',
            accessor: 'platform',
            Cell: props => {
                const type = props?.value;
                return (
                    type === 'it_infra' ?
                        <span className="text-camel-case">IT Infrastructure</span>
                    : 
                        <span className="text-camel-case">{type.replace("_", " ")}</span>
                )
            },
        },
        {
            Header: 'Asset Criticality',
            accessor: 'criticality',
            disableSortBy: true,
            Cell: props => {
                const type = props?.value;
                return (
                    <span className="text-camel-case">{type}</span>
                )
            },
        },
        {
            Header: 'Asset Risk Score',
            accessor: 'riskScore',
            disableSortBy: true
        },
    ];

    const tableData = assetList?.map((item, index) => {
        return ({
            index: get2DigitFormattedNumber(index + 1),
            asset: [item?.assetName, item?.assetId, item?.platform],
            pentest: item?.pentestName,
            platform: item?.platform,
            criticality: item?.criticality,
            riskScore: item?.riskScore,
        })
    })

    return (
        <Modal
            open={true}
            maxWidth={"lg"}
            fullWidth={true}
            dialogTitle={<>
               <Typography variant={"h5"} className={`!text-white`}>
                    <span>{title}</span>
                </Typography>
            </>}
            zeroPadding={true}
            onClose={closeModal}
        >
            <div className="text-left">
                {(isFetching) && <MainLoader />}
                <div className="my-5">
                    <div className={"max-h-[500px] px-5 overflow-auto"}>
                        {
                            !isFetching &&
                                <ReactTable
                                    columns={columns}
                                    data={tableData || []}
                                    className={"table lg:table-fixed middle-aligned"}
                                    headerClassName={"background rounded sticky z-[5] top-0"}
                                    bodyClassName={"text-headings"}
                                />
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AssetListModal;
