import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import './mui_overrides.scss'
import {DialogTitle} from "@mui/material";
import Typography from "../Typography";

const Modal = ({children, dialogTitle, subtitle, zeroPadding, closeClassName, ...props}) => {
    const {onClose} = props
    return (
        <Dialog
            {...props}
            onClose={() => {
            }}
            scroll={"body"}
            className={`modal ${zeroPadding && "zeroPadding"}`}
        >
            {dialogTitle && (
                <DialogTitle>
                    <div>
                        {typeof dialogTitle === "string" ? (
                            <Typography variant={"h4"} className={"text-white"}>
                                {dialogTitle}
                            </Typography>
                        ) : (
                            dialogTitle
                        )}
                        {subtitle && (
                            <Typography variant={"subtitle1"} className={"text-white"}>
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                </DialogTitle>
            )}
            <DialogContent>
                {
                    onClose && <CloseIcon onClick={onClose}
                                          className={`absolute right-5 top-4 cursor-pointer ${dialogTitle && 'text-white'} ${closeClassName}`}/>
                }
                {children}
            </DialogContent>
        </Dialog>
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
    dialogTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    subtitle: PropTypes.string, // New prop for the subtitle
    dialogTitleClassName: PropTypes.string,
};

export default Modal;
