import React from 'react';
import withPermissions from "../../../../hoc/withPermissions";
import VAList from './VAList';



const VulnerabilityAssessment = () => {
    return (
        <div>
            <VAList/>
        </div>
    );
};

export default withPermissions(VulnerabilityAssessment);