import axios from "axios"
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import { NOTIFICATIONS } from "./utils/constants";
import { paginationAttr } from "../actions/commonActions";


export const useNotifications = (options = {}) => {
    const {
        pageSize = 10,
    } = options
    return useInfiniteQuery([NOTIFICATIONS, { pageSize }], async ({ pageParam = 1 }) => {
        let res = await axios.get(`/notifications${paginationAttr({
            page: pageParam,
            pageSize
        })}`)
        return res?.data
    }, {
        getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
        keepPreviousData: true,
        refetchInterval: 300000,
        refetchIntervalInBackground: true
    });
}

export const useMarkNotificationAsRead = () => {
    let queryClient = useQueryClient();
    return useMutation(async (id) => {
        let res = await axios.put(`notifications/read/${id}`)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(NOTIFICATIONS);
        }
    });
}