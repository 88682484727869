import React from 'react';
import Typography from "../Typography";

const NoData = ({text, className, style = {}}) => {
    return (
        <div className={`h-[150px] flex items-center text-center ${className}`}>
            <Typography variant={"h5"} className={"flex-1 text-body body1"} style={style} >{text || "-No Data to Display-"}</Typography>
        </div>
    );
};

export default NoData;
