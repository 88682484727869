import { List, ListItem, ListItemText, Popover } from '@mui/material';
import React, { useEffect, useState } from 'react';
//import { notificationData } from './notificationData';
import Notification from './Notification';
import BellIcon from '../../SvgIcons/BellIcon';
import BellNewIcon from '../../SvgIcons/BellNewIcon';
import { useValidateLogin } from '../../../hooks/auth';
import { getMainURL } from './notification-helper';
import { useNotifications } from '../../../hooks/notifications';
import { useInView } from "react-intersection-observer";
import { getAllDataFromPages } from '../../../actions/commonActions';

const Notifications = () => {
    const [notifyEl, setNotifyEl] = useState(null);
    const open = Boolean(notifyEl);
    const [newNotification, setNewNotification] = useState(false);
    let { data } = useValidateLogin();
    const mainUrl = getMainURL(data?.user?.role);
    const {data: notificationData, fetchNextPage } = useNotifications();
    const notifications = getAllDataFromPages(notificationData?.pages, 'notifications');
    const hasUnReadNotification = notificationData?.pages[0]?.unreadNotificationCount;
    const hasNextPage = notificationData?.pages[0]?.hasNextPage;

    const handleClick = (event) => {
        setNotifyEl(notifyEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setNotifyEl(null);
    };

    const notificationIcon = newNotification ? (
        <BellNewIcon className="cursor-pointer" />
    ) : (
        <BellIcon className="cursor-pointer" />
    );

    const {inView, ref} = useInView();
    useEffect(() => {  
        if (inView) {
            fetchNextPage();
        }            
    }, [inView]);

    
    useEffect(() => {  
        if (hasUnReadNotification) {
            setNewNotification(true);
        } else {
            setNewNotification(false);
        }
    }, [hasUnReadNotification])

    const notificationsSection = notifications && notifications?.length > 0 ? (
        notifications.map((notification, index) => {
            return (
                <Notification
                    key={index}
                    notification={notification}
                    onClose={handleClose}
                    mainUrl={mainUrl}
                />
            )
        })) : (
            <ListItem alignItems="flex-start"  className="!w-[530px]">
                <ListItemText className="text-center text-body" primary={"- No notifications to display -"}/>
            </ListItem>
        );

    return (
        <div className="!w-9 text-center">
            <span onClick={handleClick}>{notificationIcon}</span>
            <Popover
                open={open}
                anchorEl={notifyEl}
                id={"account-menu"}
                onClose={handleClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                variant={"menu"}
                autoFocus={false}
                className={"hidden md:block app-notifications"}
            >
                <div className='max-h-[450px]  overflow-y-auto my-1'>
                    <List sx={{width: '100%', maxWidth: 530, bgcolor: 'background.paper' }} className="!mx-3 !my-2 !py-0">
                        {notificationsSection}
                    </List>
                    {hasNextPage && <div className={"h-[10px]"} ref={ref}></div>}
                </div>
            </Popover>
        </div>
    )
}

export default Notifications;