import React from 'react';
import PropTypes from 'prop-types';
import './typography.scss';

const headings = ["h1", "h2", "h3", "h4", "h5", "h6"]
const Typography = props => {
    let {
        variant,
        children,
        className = ``
    } = props

    switch (variant) {
        case "h1":
            return (<h1 className={className} {...props}>{children}</h1>)
        case "h2":
            return (<h2 className={className} {...props}>{children}</h2>)
        case "h3":
            return (<h3 className={className} {...props}>{children}</h3>)
        case "h4":
            return (<h4 className={className} {...props} >{children}</h4>)
        case "h5":
            return (<h5 className={className} {...props} >{children}</h5>)
        case "h6":
            return (<h6 className={className} {...props}>{children}</h6>)
        case "subtitle1":
        case "subtitle2":
        case "body1":
        case "body2":
        case "caption":
        case "h7":
            className = `${variant} ${className}`
            break;
        default:
            break;

    }
    return (
        <p  {...props} className={className}>{children}</p>
    );
};

Typography.propTypes = {
    variant: PropTypes.oneOf([...headings, "h7", "subtitle1", "subtitle2", "body1", "body2", "caption",
        "overline"]),
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default Typography;
