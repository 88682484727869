import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList, Tooltip } from 'recharts';
import { getItemType } from '../../utils/constants/severity';
import Paper from '../Dashboard/Paper';
import Typography from '../Typography';

const COLORS = ['var(--primary-500)', 'var(--tertiary-600)', 'var(--functional-purple)', 'var(--secondary-700)', 'var(--grey2)'];

const FullPieChart = (props) => {
    const {
        data = [],
        title,
        colors = COLORS,
        className,
        labelPosition = "right",
        shadow = false,
        chartClassName = '',
        titleClassName = '',
        showLabelCount,
        labelFullWidth = false,
        hideExtraLabel = true,
        showEmpty=false
    } = props;
    const total = showLabelCount ?
        data?.reduce((acc, obj) =>
            obj?.name === showLabelCount ?
                acc + obj?.value : acc + 0, 0)
        :
        data?.reduce((acc, obj) => acc + obj?.value, 0) || 0
    
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-graph-tooltip">
                    <p className="label text-camel-case">{`${payload[0].name.replace("_", " ")}: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <Paper shadow={shadow} className={`${className}`}>
            <Typography variant={"subtitle1"} className={`${titleClassName} text-headings px-5`}>{title}</Typography>
            {
                (!showEmpty || total > 0) &&
                    <div className="grid grid-cols-12">
                        <div className={`${labelPosition === "right" ? "col-span-6" : "col-span-12"}`}>
                            <div className={`h-[110px] mt-4 ${chartClassName}`}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        {/* <circle cx={"50%"} cy={"50%"} r={50} fill={"var(--background)"}/>
                                        <text
                                            x={'50%'}
                                            y={'50%'}
                                            textAnchor="middle"
                                            dominantBaseline="middle"
                                            className={"text-[25px] font-semibold fill-body"}
                                        >
                                            {get2DigitFormattedNumber(total)}
                                        </text> */}
                                        <Pie
                                            data={data}
                                            labelLine={false}
                                            outerRadius={50}
                                            fill="#8884d8"
                                            dataKey="value"
                                            startAngle={270}
                                            endAngle={-270}
                                            isAnimationActive={false}
                                        >
                                            <LabelList
                                                dataKey="value"
                                                position="inside"
                                                fill="#fff"
                                            />
                                        {data.map((entry, index) => {
                                                return (
                                                    <Cell key={`cell-${index}`} fill={colors[entry?.name]?.color} />
                                                )
                                            })}
                                        </Pie>
                                        <Tooltip content={<CustomTooltip />} />
                                        <div className="col-span-12 mt-4">
                                            <div className='flex justify-center items-center'>
                                                {data?.map((item, index) => (
                                                    <div className="flex items-center mx-2" key={index}>
                                                        <div className="inline-block w-4 h-4 rounded-full mr-2"
                                                            style={{ background: colors[item?.name]?.color }}></div>
                                                        <Typography variant={"caption"} className="capitalize">
                                                            {item?.name} ({item?.value})
                                                        </Typography>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        {
                            labelFullWidth ?
                                <div className="col-span-12 mt-4">
                                    <div className='flex items-center flex-align-center'>
                                        <div className='flex flex-col h-[130px]'>
                                            {
                                                data?.map((item, index) => {
                                                    return (
                                                        <div className="flex items-center mt-2" key={index}>
                                                            <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                                                style={{ background: colors[item?.name]?.color}}></div>
                                                            <Typography variant={"caption"} className="capitalize">
                                                                <span> {item?.name?.replace("_", " ")} {hideExtraLabel ? '' : `(Score)`}</span>
                                                            </Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={`${labelPosition === "right" ? "col-span-6 flex items-center" : "col-span-12"}`}>
                                    <div className={`${labelPosition === "right" ? "flex flex-wrap items-center gap-x-4 flex-align-center" : labelFullWidth ? "flex flex-wrap items-center h-[130px] gap-x-4 flex-align-center" : "flex flex-wrap items-center h-[40px] gap-x-4 flex-align-center"}`}>
                                        {
                                            data?.map((item, index) => {
                                                const itemType = getItemType(item);
                                                return (
                                                    <div className={`${labelPosition === "right" ? "flex-[100%]" : labelFullWidth ? "flex-[100%]" : ""}`} key={index}>
                                                        <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                                            style={{ background: colors[item?.name]?.color}}></div>
                                                        <Typography variant={"caption"}
                                                            className={`inline-block capitalize ${labelPosition === "bottom" && "text-[13px]"} `}>{item?.name?.replace("_", " ")}</Typography>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                        }
                    </div>
            }
            {
                (showEmpty && total <= 0) &&
                    <div className='body1 flex flex-align-item-center flex-align-center h-full w-full text-body font-semibold text-align-center'>-No Data to Display-</div>
            }
        </Paper>
    );
}

export default FullPieChart;