import React from 'react';

const BellNewIcon = ({ className }) => {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="currentColor" className={`inline-block ${className}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M18 33C19.65 33 21 31.65 21 30H15C15 31.65 16.335 33 18 33ZM27 24V16.5C27 11.895 24.54 8.04 20.25 7.02V6C20.25 4.755 19.245 3.75 18 3.75C16.755 3.75 15.75 4.755 15.75 6V7.02C11.445 8.04 9 11.88 9 16.5V24L6 27V28.5H30V27L27 24Z" fill="#207DA8" />
            <circle cx="14.5" cy="8.5" r="6.5" fill="#FE7754" />
        </svg>
    );
};

export default BellNewIcon;
