import React from 'react';

const SidebarExpandIcon = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.313102 8.31887C0.0383316 8.59364 -0.000804333 8.86813 -0.00080434 9.02494C-0.000804347 9.18175 0.0383306 9.49566 0.313102 9.73101L9.02304 17.7347C9.41549 18.0878 10.0431 18.0878 10.3964 17.6956C10.7494 17.3032 10.7494 16.6756 10.3572 16.3223L2.43197 9.02476L10.3572 1.72724C10.7497 1.3742 10.7888 0.746393 10.3964 0.353913C10.0433 -0.0385383 9.41552 -0.0776745 9.02304 0.314778L0.313102 8.31887Z" fill="#10635F" />
            <path d="M8.4342 8.31887C8.15943 8.59364 8.12029 8.86813 8.12029 9.02494C8.12029 9.18175 8.15942 9.49566 8.4342 9.73101L17.1441 17.7347C17.5366 18.0878 18.1642 18.0878 18.5175 17.6956C18.8705 17.3032 18.8705 16.6756 18.4783 16.3223L10.5531 9.02476L18.4783 1.72724C18.8708 1.3742 18.9099 0.746393 18.5175 0.353913C18.1644 -0.0385383 17.5366 -0.0776745 17.1441 0.314778L8.4342 8.31887Z" fill="#10635F" />
        </svg>
    );
};

export default SidebarExpandIcon;
