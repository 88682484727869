import React, {useEffect, useRef, useState} from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Divider} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useDispatch} from "react-redux";
import {setCommonInputs} from "../../redux/reducers/common";
import {Account, Logout} from "./Menu";
import {useLogout, useValidateLogin} from "../../hooks/auth";
import {useImage} from "../../hooks/misc";
import {getAvatarString} from "../../actions/commonActions";

const AccountDropDown = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const logout = useLogout()
    const dropdownEl = useRef(null);
    const open = Boolean(anchorEl)
    const dispatch = useDispatch()
    const {data} = useValidateLogin()
    const profile_picture = useImage(data?.user?.profile_picture)
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        dispatch(setCommonInputs({
            isMenuExpanded: open
        }))
    }, [open])
    return (
        <div>
            <div className={"cursor-pointer pr-2"} onClick={handleClick}>
                {
                    data?.user?.profile_picture ?
                        <img src={profile_picture} height={33} width={33}
                             className={"rounded-full w-[33px] h-[33px] inline-block mr-3.5"} alt=""/>
                        :
                        <div
                            className={"rounded-full w-[33px] bg-primary-800 h-[33px] inline-block mr-3.5 text-center"}>
                            <span
                                className={"font-semibold text-white text-[16px] align-sub"}>{getAvatarString(`${
                                data?.user?.first_name} ${data?.user?.last_name}`)}</span>
                        </div>
                }
                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon ref={dropdownEl}/>}
            </div>
            <Menu
                open={open}
                anchorEl={anchorEl}
                id={"account-menu"}
                onClose={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                variant={"menu"}
                autoFocus={false}
                className={"hidden md:block"}
            >
                <MenuItem className={"!block"}>
                    <Account/>
                </MenuItem>
                <Divider variant={"middle"}/>
                <MenuItem onClick={() => logout.mutate()}>
                    <Logout/>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default AccountDropDown;
