import React from 'react';
import PropTypes from 'prop-types';
import {useLocation} from "react-router-dom";

const ClipPath = props => {
    const location = useLocation()
    let opacity = !["/"].includes(location.pathname)
    let isDashboard = ["/app", '/admin', "/tester"].some(el => location.pathname?.startsWith(el))
    return (
        <div
            className={`clip-path primary absolute ${opacity ? "opacity" : ""} ${(isDashboard && '!h-[80%] !opacity-10 absoluteEl') || ''}`}>

        </div>
    );
};

ClipPath.propTypes = {
    opacity: PropTypes.bool
};

export default ClipPath;
