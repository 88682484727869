import React from 'react';

const SearchIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" className={"inline-block"}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0312 19C15.4495 19 19.0312 15.4183 19.0312 11C19.0312 6.58172 15.4495 3 11.0312 3C6.61297 3 3.03125 6.58172 3.03125 11C3.03125 15.4183 6.61297 19 11.0312 19Z"
                stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.0316 21.0004L16.6816 16.6504" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default SearchIcon;
