import axios from "axios";

const instance = axios.create({});
instance.defaults.headers.common = {};

export const generatePreSignedUrl = async (data) => {
    let res = await axios.post(`misc/s3/get_presigned_url`, data)
    return res.data;
}

export const uploadFile = (options) => {
    let {category, file, type: mediaType, from} = options
    return new Promise(async (resolve, reject) => {
        try {
            let {name: file_name, type, size} = file
            let payload = {
                category,
                file_name,
                file_type: type.split("/").pop(),
                mediaType,
                size
            }
            let res = await generatePreSignedUrl(payload)
            let preSignedURL = res?.signedUrl
            const reader = new FileReader()
            reader.onload = async () => {
                await instance.put(preSignedURL, reader.result)
                resolve(res?.key)
            }
            reader.readAsArrayBuffer(file)
        } catch (e) {
            if(from === 'PoC' && e?.response?.data?.message)
                resolve ({success: false, message: e?.response?.data?.message})
            else
                reject(e)
        }
    })
}

