import React from 'react';

const CompletedIcon = () => {
    return (
        <svg className={"inline-block"} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.251 15.3579C11.7951 15.3598 11.3576 15.1782 11.0371 14.8539L7.64396 11.4394C7.51181 11.3064 7.4375 11.1266 7.4375 10.939C7.4375 10.7513 7.51181 10.5715 7.64396 10.4386C7.7769 10.3063 7.9569 10.2321 8.14436 10.2321C8.33197 10.2321 8.51181 10.3063 8.64492 10.4386L12.0451 13.8388C12.0997 13.8935 12.1738 13.924 12.251 13.924C12.3283 13.924 12.4023 13.8935 12.4568 13.8388L21.5857 4.70988C21.7188 4.57773 21.8987 4.50342 22.0863 4.50342C22.2737 4.50342 22.4537 4.57773 22.5867 4.70988C22.7188 4.84282 22.7931 5.02282 22.7931 5.21028C22.7931 5.39788 22.7188 5.57773 22.5867 5.71083L13.4647 14.8397C13.1462 15.1685 12.7085 15.3552 12.2507 15.3579L12.251 15.3579Z"
                fill="#12AFB8"/>
            <path
                d="M15.5583 19.6172H4.06576C3.51782 19.6172 2.9924 19.3995 2.60497 19.0122C2.21771 18.6248 2 18.0994 2 17.5514V6.06576C2 5.51782 2.21771 4.9924 2.60497 4.60497C2.99239 4.21771 3.51782 4 4.06576 4H15.5583C16.1063 4 16.6317 4.21771 17.0189 4.60497C17.4064 4.99239 17.6241 5.51782 17.6241 6.06576V7.41451C17.6241 7.60275 17.5493 7.78323 17.4162 7.91649C17.2829 8.04958 17.1025 8.12438 16.9142 8.12438C16.726 8.12438 16.5453 8.04958 16.4122 7.91649C16.2791 7.78323 16.2043 7.60275 16.2043 7.41451V6.06576C16.2043 5.89447 16.1362 5.73016 16.0152 5.60893C15.8939 5.48787 15.7296 5.41973 15.5583 5.41973H4.06575C3.89446 5.41973 3.73015 5.48787 3.60892 5.60893C3.48786 5.73014 3.41973 5.89446 3.41973 6.06576V17.5514C3.41973 17.7227 3.48786 17.887 3.60892 18.0083C3.73014 18.1293 3.89446 18.1975 4.06575 18.1975H15.5583C15.7296 18.1975 15.8939 18.1293 16.0152 18.0083C16.1362 17.887 16.2043 17.7227 16.2043 17.5514V14.0021C16.2043 13.8138 16.2791 13.6334 16.4122 13.5001C16.5453 13.367 16.726 13.2922 16.9142 13.2922C17.1025 13.2922 17.2829 13.367 17.4162 13.5001C17.5493 13.6334 17.6241 13.8138 17.6241 14.0021V17.5514C17.6241 18.0994 17.4064 18.6248 17.0189 19.0122C16.6317 19.3995 16.1063 19.6172 15.5583 19.6172Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default CompletedIcon;
