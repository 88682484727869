import React from 'react';
import styles from './styles.module.scss'

const Paper = ({children, className, shadow = false, ...paperProps}) => {
    return (
        <div className={`${styles.paper} ${className} ${shadow && "shadow-dropdown"}`} {...paperProps} >
            {children}
        </div>
    );
};

export default Paper;
