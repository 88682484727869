import {useMutation, useQuery} from "react-query";
import {createCustomer} from "../api/mutations/customer";
import Storage from "../utils/Storage";
import {COMPANY, CUSTOMER_DETAILS, NEXT_PENTEST_NAME} from "./utils/constants";
import {customerDetails} from "../api/queries/customer";
import axios from "axios";

export const useCreateCustomer = () => {
    return useMutation(createCustomer)
}

export const useCustomerDetails = (enabled = true) => {
    return useQuery(CUSTOMER_DETAILS, customerDetails, {
        retry: false, staleTime: Infinity,
        enabled: Boolean(Storage.token) && enabled,
    })
}

export const useNextPentestName = (enabled = true) => {
    return useQuery(NEXT_PENTEST_NAME, async () => {
        let res = await axios.post(`/temp/generate_pentest_name`)
        return res?.data?.nextPentestName
    }, {
        enabled
    })
}

export const useCompany = (company_id) => {
    return useQuery([COMPANY, {company_id}], async () => {
        let res = await axios.get(`/company/${company_id}`)
        return res.data
    }, {
        enabled: !!company_id
    })
}

export const useUpdateCompanyName = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`/company/${data.company_id}/update`, ...data?.companyDetails)
        return res.data;
    }, options)
}
