// assets
export const WEB_APP = 'web_app';
export const API = 'api';
export const MOBILE_APP = 'mobile_app';
export const IT_INFRASTRUCTURE = 'it_infra';
export const CLOUD = 'cloud';

export let availability = {
        [WEB_APP]: true,
        [API]: true,
        [MOBILE_APP]: true,
        [IT_INFRASTRUCTURE]: true,
        [CLOUD]: false
};
