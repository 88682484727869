import React from 'react';

const BellIcon = ({ className }) => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="currentColor" className={`inline-block ${className}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 30C13.65 30 15 28.65 15 27H9C9 28.65 10.335 30 12 30ZM21 21V13.5C21 8.895 18.54 5.04 14.25 4.02V3C14.25 1.755 13.245 0.75 12 0.75C10.755 0.75 9.75 1.755 9.75 3V4.02C5.445 5.04 3 8.88 3 13.5V21L0 24V25.5H24V24L21 21Z" fill="#207DA8" />
        </svg>

    );
};

export default BellIcon;
