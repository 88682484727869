import React from 'react';
import {useImage} from "../../hooks/misc";

const Image = ({src, type, ...rest}) => {
    let url = useImage(src, type) 
    return (
        <img src={url} {...rest} alt=""/>
    );
};

export default Image;
