import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from "../../../../components/Button";
import { get2DigitFormattedNumber, getAllDataFromPages } from '../../../../actions/commonActions';
import MainLoader from "../../../../components/Loader/MainLoader";
import { useInView } from "react-intersection-observer";
import { assetDepartment, useAssetManagement, useListCustomer } from '../../../../hooks/dashboard';
import AllTableData from '../../../../components/Dashboard/AllTableData';
import Paper from "../../../../components/Dashboard/Paper";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DeleteIcon from '@mui/icons-material/Delete';
import BackspaceIcon from '@mui/icons-material/Backspace';
import CloudIcon from '../../../../components/SvgIcons/CloudIcon';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MonitorIcon from '@mui/icons-material/Monitor';
import ApiIcon from '../../../../components/SvgIcons/ApiIcon';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { MuiCheckBox, MuiTextField } from '../../../../components/MuiComponents/FormInputs';
import SearchIcon from '../../../../components/SvgIcons/SearchIcon';
import { Divider, MenuItem, Typography } from '@mui/material';
import Status from './Status';
import { AlertContext } from '../../../../components/SuccessOrFailedModal';
import { useDispatch } from 'react-redux';
import LongMenu from '../../../../components/MuiComponents/MuiMenu';
import RecommissionIcon from '../../../../components/SvgIcons/RecommissionIcon';
import { MOBILE_APP } from '../../PentestScope/AddNewAssets/utils/asset_constants';
import { assetCriticalityMapping, os_type } from "../../../../utils/constants/asset";
import { setDecommissionAssetsModal, setDeleteAssetsModal, setAddNewAssetsModal, setRecommissionAssetsModal } from '../../../../redux/reducers/dashboard';
import { setEditAssetsModal } from '../../../../redux/reducers/dashboard';
import { Checkbox } from 'react-table';

const AssetList = ({ filterTitle }) => {
    const [tableState, setTableState] = useState(null)
    const [selectedRows, setSelectedRows] = useState([]);
    const [departmentsList, updateDepartmentsList] = useState(null);
    //const userTabs = ["Customers", "Siemba"]
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [companyId, updateCompanyId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [typingTimeOut, setTypingTimeout] = useState(null);
    //const [tabIndex, setTabIndex] = useState(0)
    const [filter, setFilter] = useState(null)
    const [search, setSearch] = useState(null)

    const [popup] = useContext(AlertContext)
    const dispatch = useDispatch();

    const rowReset = () => {
        setSelectedRows([])
    }
    
    const comingSoon = () => {
        popup({
            title: "Coming soon",
            variant: "success",
            okayText: "Okay",
        })
    }
    
    const options = [
        { 
            value: 'edit', 
            label: 'Edit', 
            labelClass: 'text-primary-500',
            isGreyed: false,
            icon: <BorderColorIcon/>,
            iconClassName: "!text-headings !text-primary-500 !rounded-full"
        },
        {
            value: 'duplicate', 
            label: 'Duplicate', 
            labelClass: 'text-secondary-700',
            isGreyed: false,
            icon: <LibraryBooksIcon/>,
            iconClassName: "!text-headings !text-secondary-700 !rounded-full"
        },
        {
            value: 'delete', 
            label: 'Delete', 
            labelClass: 'text-tertiary-600',
            isGreyed: false,
            icon:  <DeleteIcon/>,
            iconClassName: "!text-headings !text-tertiary-600 !rounded-full"
        },
        {
            value: 'decommission',
            label: 'Decommission', 
            isGreyed: false,
            labelClass: 'text-decommission-orange',
            icon: <BackspaceIcon/>,
            iconClassName: "!text-headings !text-decommission-orange !rounded-full"
        },
        {
            value: 'recommission', 
            label: 'Recommission',
            isGreyed: false, 
            labelClass: 'text-tertiary-900',
            icon: <RecommissionIcon/>,
            iconClassName: "!text-headings !text-tertiary-900 !rounded-full"
        }
    ];

    const { data: companyData, fetchNextPage: fetchNextPageCustomer, hasNextPage: hasNextPageCustomer, isFetching: isFetchingCustomer } = useListCustomer({
        pageSize: 30,
        search: inputValue,
    });

    // const {}

    const customers = useMemo(
        () =>
            getAllDataFromPages(companyData?.pages, 'customers').map(({ company_name: companyName, id }) => ({
                label: companyName,
                id,
            })),
        [companyData]
    );

    const { inView, ref } = useInView();

    //const showFirstTab = tabIndex === 0
    //const showSecondTab = tabIndex === 1

    // useEffect(() => {
    //     console.log("tablestate",tableState)
    // }, [tableState])

    


    const {
        data: userData,
        isFetching: isFetchingUser,
        fetchNextPage: fetchNextUser,
        hasNextPage: hasNextUser
    } = useAssetManagement({
        search,
        sortBy: tableState?.sortBy,
        company_id: filter && filter.Company ? filter.Company : selectedCustomer?.id,
        platform: filter && filter.Platform ? filter.Platform : null,
        department: filter && filter.Department ? filter.Department : null,
        priority: filter && filter.Asset_Criticality? filter.Asset_Criticality : null,
        stage: filter && filter.Stage ? filter.Stage : null,
    })

    

    const { ref: firstTabRef, inView: inViewFirstTab } = useInView()

    useEffect(() => {
        fetchNextUser?.()
    }, [inViewFirstTab])

    const handleDepartmentRefresh = async (companyId) => {
        updateDepartmentsList(null);
        const assetDepartments = await assetDepartment({ company_id: companyId });
        const formattedData = [];
        assetDepartments.data.map((department) => {
            formattedData.push({
                "name": department.department,
                "id": department.department,
            });
        });
        updateDepartmentsList(formattedData);
    }

    useEffect(() => {
        handleDepartmentRefresh(companyId);
    }, [companyId]);
    
    let totalAssetCount = userData?.pages[0].asset_count;
    let assetDetails = getAllDataFromPages(userData?.pages, "assets") || []; 
    let companyNames = getAllDataFromPages(userData?.pages, "companyNames") || [];

    const onTableChange = (tableState) => {
        setTableState(tableState)
    }

    const onChangeCustomer = (currentCustomer) => () => {
        if (currentCustomer.id !== selectedCustomer?.id) {
            currentCustomer.value = currentCustomer.id;
            updateCompanyId(currentCustomer.id);
            setSelectedCustomer(currentCustomer);
        } else {
            setSelectedCustomer(null);
        }
    }

    const onInputChange = (event) => {
        const currentInputValue = event.target.value.toLowerCase();
        setSearchValue(currentInputValue);
        if (typingTimeOut) {
            clearTimeout(typingTimeOut);
        }
        setTypingTimeout(
            setTimeout(() => {
                setInputValue(currentInputValue);
            }, 500)
        );
    };

    const onKeyDown = (e) => {
        e.stopPropagation();
    }

    const handleCheckboxChange = (id, name, stage, company_id, pentest_id, asset_poc) => {
        setSelectedRows((prevSelectedRows) => {
          const existingRow = prevSelectedRows.find((row) => row.id === id);
          if (existingRow) {
            return prevSelectedRows.filter((row) => row.id !== id);
          } else {
            return [...prevSelectedRows, { id, name, stage, company_id, pentest_id, asset_poc }];
          }
        });
      };
      

    let columns = [
        {
            Header: '',
            accessor: 'checkbox',
            Cell: props => {
                let item = props.value
                const isDisabled = item.stage === 'not_scheduled' || item.stage === 'completed' || item.stage === 'decommissioned';
                const isChecked = selectedRows.some(jsonObj => jsonObj.id === item.id);
                return (
                    <>
                        <MuiCheckBox className={"!py-0"}
                                     name={"asset_id"}
                                     value={item.id}
                                     onChange={() => handleCheckboxChange(item.id, item.name, item.stage, item.company_id, item.pentest_id, item.asset_poc)}
                                     checked={isChecked}
                                     disabled={!isDisabled}
                        />
                    </>
                )
            },
            disableSortBy: true
        },
        {
            Header: 'Asset',
            accessor: 'asset',
        },
        {
            Header: 'Platform',
            disableSortBy: true,
            accessor: 'platform',
            Cell: props => {
                const [platform, type] = props?.value
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div">{showPlatformIcon(platform)}</span>
                        {
                            platform === MOBILE_APP &&
                            <span> ({os_type?.[type]?.title})</span>
                        }
                    </div>
                )
            },
        },
        {
            Header: 'Asset Category',
            accessor: 'asset_category',
        },
        {
            Header: 'Department',
            accessor: 'department',
        },
        {
            Header: 'Pentest',
            accessor: 'pentestCount',
            Cell: props => {
                const pentest_count = props?.value
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div"> {pentest_count}</span>
                    </div>
                )
            },
        },
        {
            Header: 'Asset Criticality',
            accessor: 'priority'
        },
        {
            Header: 'Stage',
            accessor: 'stage',
            Cell: props => {
                const stage = props?.value
                return (
                    <div className="inline-flex items-center">
                        <span className="user-div"> {readableStage(stage)}</span>
                    </div>
                )
            },
        },
        {
            Header: '',
            accessor: 'menuItem',
            disableSortBy: true,
            className: 'w-[48px]', 
            Cell: props => {
                        const [asset, stage] = props?.value;
                        // defining which buttons to display for each asset stage
                        const btnsForEachStage = {
                            'not_scheduled': ['edit', 'duplicate', 'delete', 'decommission'],
                            'scheduled_for_test': ['edit', 'duplicate', 'delete', 'decommission'],
                            'inflight': ['edit', 'duplicate', 'delete', 'decommission'],
                            'prerequisites': ['edit', 'duplicate', 'delete', 'decommission'],
                            'testing': ['edit', 'duplicate', 'delete', 'decommission'],
                            'remediation': ['edit', 'duplicate', 'delete', 'decommission'],
                            'completed': ['edit', 'duplicate', 'delete', 'decommission'],
                            'decommissioned': ['edit', 'duplicate', 'delete', 'recommission']
                        }

                        // defining which assets should be visible according to asset stage
                        const validation = {
                            'not_scheduled': ['edit', 'duplicate', 'delete', 'decommission'],
                            'scheduled_for_test': ['edit', 'duplicate'],
                            'inflight': ['edit', 'duplicate'],
                            'prerequisites': ['edit', 'duplicate'],
                            'testing': ['edit', 'duplicate'],
                            'remediation': ['edit', 'duplicate'],
                            'completed': ['edit', 'duplicate', 'delete', 'decommission'] ,
                            'decommissioned': ['delete', 'duplicate', 'recommission']
                        }

                        //  greying out if a btn is not present in the list of asset stage button validation
                        const btnsRequired = btnsForEachStage[stage]?.map(btn => {
                            const isGreyed = !validation[stage]?.includes(btn);
                            return { ...options.find(o => o.value === btn), isGreyed };
                        }) || [];

                       
                        const editAsset = () => {
                            dispatch(setEditAssetsModal({
                                open: true,
                                company_id: selectedCustomer?.id,                
                                asset: asset
                            }))
                        }

                        const decommissionAsset = () => {
                            //setSelectedRows([{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id, asset:asset}]);
                            dispatch(setDecommissionAssetsModal({
                                open: true,
                                selectedRows: [{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id, asset_poc: asset?.asset_poc,  asset:asset}],
                                setSelectedRows: setSelectedRows
                            }))
                        }

                        const deleteAsset = () => {
                            //setSelectedRows([{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id, asset:asset}]);
                            dispatch(setDeleteAssetsModal({
                                open: true,
                                selectedRows: [{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id, asset_poc: asset?.asset_poc, asset:asset}],
                                setSelectedRows: setSelectedRows
                            }))
                        }

                        const recommmissionAsset = () => {
                            //setSelectedRows([{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id}]);
                            dispatch(setRecommissionAssetsModal({
                                open: true,
                                selectedRows: [{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id, asset_poc: asset?.asset_poc, asset:asset}],
                                setSelectedRows: setSelectedRows
                            }))
                        }

                        const duplicateAsset = () => {
                            //setSelectedRows([{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id}]);
                            dispatch(setAddNewAssetsModal({
                                open: true,
                                company_id: asset?.company_id,
                                asset: asset,
                                selectedRows: [{id:asset?.id, name:asset?.name, company_id: asset?.company_id, pentest_id:asset?.pentest_id}],
                                setSelectedRows: setSelectedRows,
                                action:'duplicate_asset'
                            }))
                        }

                        btnsRequired.forEach(btn=> {
                            if (btn.value === 'edit'){
                                btn.click = editAsset
                            }else if(btn.value === 'duplicate'){
                                btn.click = duplicateAsset
                            }
                            else if(btn.value === 'decommission'){
                                btn.click = decommissionAsset
                            }
                            else if(btn.value === 'recommission'){
                                btn.click = recommmissionAsset
                            }
                            else if(btn.value === 'delete'){
                                btn.click = deleteAsset
                            }
                        })

                        return (
                            <>
                                <LongMenu options = {btnsRequired}/>
                            </>
                        )
                    },
        }
    ]
    let tableData = assetDetails?.map((item, index) => {
        return ({
            checkbox: item,
            index: get2DigitFormattedNumber(index + 1),
            asset: item?.name,
            platform: [item?.platform_category, item?.type_of_os],
            asset_category: item?.asset_category,
            department: item?.department,
            pentestCount: item?.pentestCount,
            priority: assetCriticalityMapping[item?.priority],
            stage: item?.stage,
            menuItem: [item, item?.stage] //passing stage to three dots menu to do validation to grey out buttons
        })
    })

    let hiddenColumns = []

    const filterOptionsCustomer = [
        {
            name: "Company",
            type: "dropdown",
            filterValues: companyNames,
            firstFilter: true,
            multiSelector: false,
            fullWidth: true,
            singleSelect: true,
            isClearable: true,
        },
        {
            name: "Stage",
            type: "dropdown",
            multiSelector: true,
            filterValues:
                [
                    {
                        "name": "Not Scheduled",
                        "id": "not_scheduled",
                    },
                    {
                        "name": "Scheduled for Test",
                        "id": "scheduled_for_test",
                    },
                    {
                        "name": "Completed",
                        "id": "completed",
                    },
                    {
                        "name": "Decommissioned",
                        "id": "decommissioned",
                    },
                    // {
                    //     "name": "Final Report",
                    //     "id": "final_report",
                    // },
                    {
                        "name": "Pre-Requisites",
                        "id": "prerequisites",
                    },
                    {
                        "name": "Testing",
                        "id": "testing",
                    }
                    ,{
                        "name": "Remediation",
                        "id": "remediation",
                    },
                ],
        },
        {
            name: "Asset Criticality",
            type: "dropdown",
            filterValues:
                [
                    {
                        "name": "Mission Critical",
                        "id": "P1",
                    },
                    {
                        "name": "Business Critical",
                        "id": "P2",
                    },
                    {
                        "name": "Significant Impact",
                        "id": "P3",
                    },
                    {
                        "name": "Limited Impact",
                        "id": "P4",
                    },
                    {
                        "name": "Negligible Impact",
                        "id": "P5",
                    },

                ],
            multiSelector: true,
        },
        {
            name: "Department",
            type: "dropdown",
            filterValues: departmentsList, //(assetDepartments)
            multiSelector: true,
        },
        {
            name: "Platform",
            type: "dropdown",
            filterValues:
                [
                    {
                        "name": platformMapping["mobile_app"],
                        "id": "mobile_app",
                    },
                    {
                        "name": platformMapping["web_app"],
                        "id": "web_app"
                    },
                    {
                        "name": platformMapping["api"],
                        "id": "api"
                    },
                    {
                        "name": platformMapping["it_infra"],
                        "id": "it_infra"
                    },
                    {
                        "name": platformMapping["cloud"],
                        "id": "cloud"
                    }

                ],
            lastFilter: true,
            multiSelector: true
        }
    ];

    return (
        <div>
{
        selectedCustomer ? 
        <div>
            <Status company={selectedCustomer}/>
            <div className="asset-list">
            {isFetchingUser && <MainLoader />}
            <AllTableData
                title={"All Assets"}
                searchText={"Search for any assets,keywords..."}
                description={"See the list of all your assets below"}
                columns={columns}
                tableData={tableData}
                dependencyArray={[assetDetails, selectedRows]}
                onTableChange={onTableChange}
                onSearch={value => setSearch(value)}
                tableClassName={"lg:!max-h-[calc(100vh-240px)]"}
                filterTitle={filterTitle}
                moreRef={firstTabRef}
                hasNextPage={hasNextUser}
                hiddenColumns={hiddenColumns}
                filterEnabled={true}
                filterOptions={filterOptionsCustomer}
                setFilter={setFilter}
                //tabIndex={tabIndex}
                setSearch={setSearch}
                selectedCustomer={selectedCustomer}
                setSelectedCustomer={setSelectedCustomer}
                totalAssetCount={totalAssetCount}
                setInputValue={setInputValue}
                setSearchValue={setSearchValue}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                rowReset={rowReset}
                updateCompanyId={updateCompanyId}
            />
        </div>
        </div>
         : 
         <Paper className={'h-[calc(100vh_-_172px)]'}>
            <div className="grid grid-cols-12 px-5 gap-3">
                <div className="col-span-12 sm:col-span-6 lg:col-span-7">
                    <Typography variant={"h5"}><span className={"font-semibold capitalize"}>All Assets</span></Typography>
                    <Typography variant={"subtitle1"} className={"!font-regular mt-1.5"}>See the list of all your assets below</Typography>
                </div>
            </div>
            <div className="flex justify-center h-[calc(100%_-_80px)] items-center bg-background m-4 rounded">
        
            <div className="">
                <div className="name font-bold fz-16-lh-22">
                    Choose Company
                </div>
                <MuiTextField
                    label=""
                    select
                    style={{ width: 358 }}
                    className="text-left"
                    // InputProps={{
                    //     ...register("customer_details")
                    // }}
                    //error={Boolean(errors?.customer_details)}
                    //helperText={errors?.customer_details?.message}
                    SelectProps={{
                        value: selectedCustomer || '',
                        displayEmpty: true,
                        renderValue: ({ label }) => label,
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 275,
                                },
                            },
                            MenuListProps: {
                                style: {
                                    padding: 0,
                                },
                            },
                        },
                    }}
                >
                    <div className="p-2.5 sticky top-0 bg-white z-10" tabIndex={null}>
                        <div className='border border-grey1 rounded-[5px] p-1 w-full flex'>
                            <SearchIcon />
                            <input
                                onChange={onInputChange}
                                autoFocus
                                className='inline-block align-middle flex-grow ml-4 outline-none w-full'
                                placeholder='Search here...'
                                onKeyDown={onKeyDown}
                                value={searchValue}
                            />
                        </div>
                    </div>
                    <div>
                        {customers.map((item) => (
                            <>
                                <MenuItem
                                    onKeyDown={onKeyDown}
                                    value={item}
                                    key={item.id}
                                    onClick={onChangeCustomer(item)}
                                    autoFocus={false}
                                >
                                    <MuiCheckBox checked={selectedCustomer?.id === item.id} />
                                    <div className="flex">
                                        <Typography variant='caption' className='self-center ml-2'>
                                            {item.label}
                                        </Typography>
                                    </div>
                                </MenuItem>
                                <Divider variant='middle' className='!m-0' />
                            </>
                        ))}
                        {customers?.length === 0 && <div className="text-center p-2">No Customers</div>}
                    </div>
                    {hasNextPageCustomer && <div className='h-[10px]' ref={ref}></div>}
                </MuiTextField>
                </div>
            </div>
         </Paper>
        

    }
        </div>
        
    );
};

const platformMapping = {
    "web_app": 'Web Application',
    "api": 'API',
    "mobile_app": 'Mobile App',
    "it_infra": 'Infrastructure',
    "cloud": 'Cloud',
  };

  const showPlatformIcon = (platformData) => {
    let platform = platformData;

    if(platform === 'mobile_app'){      
        return <PhoneAndroidIcon className={"text-tertiary-600"}/>
    }else if (platform === 'web_app') {
        return <MonitorIcon className={"text-primary-500"}/>
    }
    else if (platform === 'api') {
        return <ApiIcon/>
    }
    else if (platform === 'it_infra') {
        return <DeviceHubIcon className={"text-primary-500"}/>
    }
    else if (platform === 'cloud') {
        return <CloudIcon/>
    }else{
        return platform;
    }
}

const readableStage = (stage) => {
    const AssetStageTypes = {
        'not_scheduled': 'Not Scheduled',
        'scheduled_for_test': 'Scheduled for Test',
        // 'inflight': 'Inflight',
        'completed': 'Completed',
        'decommissioned': 'Decommissioned',
        'prerequisites': 'Pre-Requisites',
        'testing': 'Testing',
        'remediation': 'Remediation',
        //'final_report': 'Final Report'
      };
    return AssetStageTypes[stage] || stage;
}

export default AssetList;