import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import axios from "axios";
import {API_URL} from "./utils/constants";
import {BrowserRouter} from "react-router-dom";
import Storage from "./utils/Storage";
import ScrollToTop from "./ScrollToTop";
import momentTimezone from 'moment-timezone';

axios.defaults.baseURL = API_URL
axios.defaults.headers.common.Authorization = `Bearer ${Storage.token}`
axios.defaults.headers.common.company_id = Storage.getData('company_id')
axios.defaults.headers.common.timezone = momentTimezone?.tz?.guess()

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
