import React, { Suspense } from 'react';
import Sidebar from "./Sidebar";
import styles from './layout.module.scss'
import { SwipeableDrawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setDrawer } from "../../../redux/reducers/common";
import Explore from "../../../containers/Explore";
import MainLoader from "../../Loader/MainLoader";
import WebsocketProvider from "../../../Providers/WebsocketProvider";
import { useValidateLogin } from '../../../hooks/auth';
import { PRODUCT_FRUITS_WORKSPACE_CODE } from '../../../utils/constants';
import sha256 from 'crypto-js/sha256';
import { ProductFruits } from 'react-product-fruits';
import { siembaRoles } from '../../../utils/roles';
import { customerRoles } from '../../../utils/constants/customerRoles';
import CTEMKeyInsightModal from '../../../containers/pages/Admin/CTEM/keyInsights/CTEMKeyInsightsModal.js';
import EASMKeyInsightModal from '../../../containers/pages/MyDashboard/AllEASM/keyinsights/EASMKeyInsightsModal.js';
import AssetListModal from '../../../containers/pages/Admin/CTEM/assetListModal.js';
import PrioritizedFindingModal from '../../../containers/pages/Admin/CTEM/funnelDetails/prioritizedFindingModal.js';

const AddCustomerModal = React.lazy(() => import("./AddCustomerModal"))
const AddUsersModal = React.lazy(() => import("./AddUsersModal"))
const DecommissionAssetsModal = React.lazy(() => import("./DecommissionAssetsModal"))
const RecommissionAssetsModal = React.lazy(() => import("./RecommissionAssetsModal"))
const DeleteAssetsModal = React.lazy(() => import("./DeleteAssetsModal"))
const AddNewAssetsModal = React.lazy(() => import("./AddNewAssetsModal"))
const EditAssetsModal = React.lazy(() => import("./EditAssetModal/index.js"))
const AddFindingsModal = React.lazy(() => import("./AddFindingsModal"))
const NewPentestModal = React.lazy(() => import("./NewPentestModal"))
const NewEASMModal = React.lazy(() => import("./NewEASMModal"))
const AddBlockerModal = React.lazy(() => import("./AddBlockerModal"))
const KeyInsightModal = React.lazy(() => import("./KeyInsightModal"))
const ReportModal = React.lazy(() => import("./ReportModal"))
const QualysReportModal = React.lazy(() => import("./QualysReportModal.js"))
const IPCountPreviewModal = React.lazy(() => import("./IPCountModal.js"))
const InviteCustomerModal = React.lazy(() => import("./InviteCustomerModal"))
const ImportAssetsModal = React.lazy(() => import("./ImportAssetsModal"))
const ImportGenptTestsModal = React.lazy(() => import('./ImportGenPTTestsModal'));
const EditCompanyModal = React.lazy(() => import("./EditCompanyModal/index.js"))
const SeverityChangeModal = React.lazy(() => import("./SeverityChangeModal"));
const SubscribeModal = React.lazy(() => import("./SubscribeModal.js"));
const GenptSubscribeModal = React.lazy(() => import("./GenptSubscribeModal.js"));
const EasmSubscribeModal = React.lazy(() => import("./EasmSubscribeModal.js"));
const GenptReportModal = React.lazy(() => import("./GenPTReportModal.js"));
const SetupVAModal = React.lazy(() => import("./SetupVAModal.js"));
const SPFConfigurationModal = React.lazy(() => import("./../../../containers/pages/Admin/EASM/emailSecurityConfiguration/spfConfigurationModal.js"));
const DKIMConfigurationModal = React.lazy(() => import("./../../../containers/pages/Admin/EASM/emailSecurityConfiguration/dkimConfigurationModal.js"));
const DMARCConfigurationModal = React.lazy(() => import("./../../../containers/pages/Admin/EASM/emailSecurityConfiguration/dmarcConfigurationModal.js"));
const DomainKeyInsightModal = React.lazy(() => import("../../../containers/pages/MyDashboard/DomainDashboard/keyinsights/domainOverall/DomainKeyInsightModal.js"));
const DomainSpecificKeyInsightModal = React.lazy(() => import("../../../containers/pages/MyDashboard/DomainDashboard/keyinsights/domainSpecific/DomainSpecificKeyInsightModal.js"));

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const { openDrawer, expandDrawer } = useSelector(state => state.common)
    const { addCustomerModal } = useSelector(state => state.customer)
    const {
        addUsersModal,
        decommissionAssetsModal,
        recommissionAssetsModal,
        deleteAssetsModal,
        addNewAssetsModal,
        addFindingsModal,
        newPentestModal,
        newEASMModal,
        addBlockerModal,
        keyInsightsModal,
        reportModal,
        qualysReportModal,
        genptReportModal,
        ctemKeyInsightsModal,
        easmKeyInsightsModal,
        prioritizedFindingModal,
        assetListModal,
        IPCountModal,
        inviteCustomerModal,
        importAssetsModal,
        importGenptTestsModal,
        editAssetsModal,
        editCompanyModal,
        severityChangeModal,
        subscribeModal,
        genptSubscribeModal,
        easmSubscribeModal,
        setupVAModal,
        spfModal,
        dkimModal,
        dmarcModal,
        domainKeyInsightsModal,
        domainSpecificKeyInsightsModal
    } = useSelector(state => state.dashboard)

    const { data } = useValidateLogin()

    return (
        <div>
            {PRODUCT_FRUITS_WORKSPACE_CODE ? <ProductFruits workspaceCode={PRODUCT_FRUITS_WORKSPACE_CODE} language="en" user={{
                username: sha256(data?.user?.uuid).toString(),
                firstname: data?.user?.first_name,
                lastname: data?.user?.last_name,
                role: Object.keys(siembaRoles).includes(data?.user?.role) ? "S".concat("_", siembaRoles[data?.user?.role]?.priority.toString())
                    : Object.keys(customerRoles).includes(data?.user?.role) ? "C".concat("_", customerRoles[data?.user?.role]?.priority.toString()) : null,
                signUpAt: data?.user?.created_at
            }} /> : undefined}
            <WebsocketProvider>
                <Sidebar />
                <Explore />
                <SwipeableDrawer
                    open={openDrawer}
                    onClose={() => dispatch(setDrawer(false))}
                    onOpen={() => dispatch(setDrawer(true))}
                >
                    <div className={styles.sidebarDrawer}>
                        <Sidebar from={"drawer"} />
                    </div>
                </SwipeableDrawer>
                <div id={"dashboardMainContent"} className={`${styles.main} ${!expandDrawer ? styles.expandMain : ''}`}>
                    {children}
                </div>
                <Suspense fallback={<MainLoader />}>
                    {addCustomerModal && <AddCustomerModal />}
                    {addUsersModal?.open && <AddUsersModal />}
                    {decommissionAssetsModal?.open && <DecommissionAssetsModal />}
                    {recommissionAssetsModal?.open && <RecommissionAssetsModal />}
                    {deleteAssetsModal?.open && <DeleteAssetsModal />}
                    {addNewAssetsModal?.open && <AddNewAssetsModal />}
                    {editAssetsModal?.open && <EditAssetsModal />}
                    {addFindingsModal?.open && <AddFindingsModal />}
                    {newPentestModal?.open && <NewPentestModal />}
                    {newEASMModal?.open && <NewEASMModal />}
                    {addBlockerModal?.open && <AddBlockerModal />}
                    {keyInsightsModal?.open && <KeyInsightModal />}
                    {ctemKeyInsightsModal?.open && <CTEMKeyInsightModal />}
                    {easmKeyInsightsModal?.open && <EASMKeyInsightModal />}
                    {domainKeyInsightsModal?.open && <DomainKeyInsightModal />}
                    {domainSpecificKeyInsightsModal?.open && <DomainSpecificKeyInsightModal />}
                    {prioritizedFindingModal?.open && <PrioritizedFindingModal />}
                    {assetListModal?.open &&  <AssetListModal />}
                    {reportModal?.open && <ReportModal reportPreview={reportModal?.report} user={data.user} />}
                    {qualysReportModal?.open && <QualysReportModal reportPreview={QualysReportModal?.report} user={data.user} />}
                    {IPCountModal?.open && <IPCountPreviewModal reportPreview={IPCountModal?.report} user={data.user} />}
                    {inviteCustomerModal?.open && <InviteCustomerModal />}
                    {importAssetsModal?.open && <ImportAssetsModal />}
                    {importGenptTestsModal?.open && <ImportGenptTestsModal />}
                    {editCompanyModal?.open && <EditCompanyModal />}
                    {severityChangeModal?.open && <SeverityChangeModal />}
                    {subscribeModal?.open && <SubscribeModal/>}
                    {genptSubscribeModal?.open && <GenptSubscribeModal/>}
                    {easmSubscribeModal?.open && <EasmSubscribeModal/>}
                    {genptReportModal?.open && <GenptReportModal/>}
                    {setupVAModal?.open && <SetupVAModal/>}
                    {spfModal?.open && <SPFConfigurationModal />}
                    {dkimModal?.open && <DKIMConfigurationModal />}
                    {dmarcModal?.open && <DMARCConfigurationModal />}
                </Suspense>
            </WebsocketProvider>
        </div>
    );
};

export default Layout;
