import React from 'react';
import withPermissions from "../../../../hoc/withPermissions";
import { SIEMBA_ADMIN, SIEMBA_TESTER } from "../../../../utils/roles";
import Status from "./Status";
import UsersList from './UsersList';



const UserManagement = () => {
    return (
        <div>
            <Status />
            <UsersList/>
        </div>
    );
};

export default withPermissions(UserManagement, { roles: [SIEMBA_ADMIN] });