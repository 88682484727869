export const PENTEST_MANAGER = "pentest_manager";
export const PENTEST_OVERSEER = "pentest_overseer";
export const POINT_OF_CONTACT = "point_of_contact";
export const CUSTOMER_LEADER = "customer_leader";

export const customerRoles = {
    [CUSTOMER_LEADER]: {
        role: CUSTOMER_LEADER,
        title: "Customer Leader",
        disabled: false,
        description: ["Can access and manage all aspects of Pentests and Assets associated with the enterprise.",
            "Can invite new users to the enterprise.",
            "Can manage access of the users in the enterprise.",
            "Can chat with the involved staff to clarify doubts.",
            "Can generate reports."],
        priority: 1 
    },
    [PENTEST_MANAGER]: {
        role: PENTEST_MANAGER,
        title: "Pentest Manager",
        disabled: false,
        description: ["Can access and manage all aspects of Pentests and Assets assigned to the user account.",
        "Can chat with the involved staff to clarify doubts.",
            "Can generate reports."],
        priority: 2
    },
    [POINT_OF_CONTACT]: {
        role: POINT_OF_CONTACT,
        title: "Point of Contact",
        disabled: false,
        description: ["Can access and manage findings of all Assets assigned to the user account.",
            "Can chat with the involved staff to clarify doubts.",
            "Can generate reports."],
        priority: 3
    },
    [PENTEST_OVERSEER]: {
        role: PENTEST_OVERSEER,
        title: "Pentest Overseer",
        disabled: false,
        description: ["Can only access all Pentests and Assets assigned to the user account.",
        "Can chat with the involved staff to clarify doubts.",
        "Can generate reports."],
        priority: 4
    }
};