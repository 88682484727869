import React, { useContext, useState } from 'react';
import Typography from "../../components/Typography";
import styles from "../pages/Admin/Dashboard/dashboard.module.scss";
import moment from "moment";

const testing_stage = {
    prerequisites: "Pre - Requisites",
    testing: "Testing",
    remediation: "Remediation",
    final_report: "Final Report",
}
const VulnerabilityAssessment = ({ id, info}) => {
    const [activeTab, setActiveTab] = useState('total');

    const handleTabClick = (tab, ipsCount) => {
        if (ipsCount) {
            setActiveTab(tab);
        }
      };
    
      const renderHosts = (hosts, range, type = null) => {
        return (
            <div>
                {
                    (range && (range.length > 0)) &&
                        <div className='mt-4 tile-container'>
                            {
                                range?.map((range) => (
                                    <div className={`${type === 'active' ? 'active-tile' : 'bg-secondary-100'} ${type === 'inactive' ? 'inactive-tile' : 'bg-secondary-100'} text-center rounded mb-3 mr-3 tile-content`}>
                                        <Typography variant={"h9"} className={"!text-bold"}>{range}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                }

                {
                    (hosts && (hosts.length > 0)) &&
                        <div className='mt-4 flex flex-wrap tile-container'>
                            {hosts.map((host, index) => {
                                return(
                                    <div className={`${type === 'active' ? 'active-tile' : 'bg-secondary-100'} ${type === 'inactive' ? 'inactive-tile' : 'bg-secondary-100'} text-center rounded mb-3 mr-3 tile-content`}>
                                        <Typography key={index} variant={"h9"} className={"!text-bold"}>{host}</Typography>
                                    </div>
                                )
                            })}
                        </div>
                }
            </div>
        );
      };
    
    return (
        <div>
            <div className="flex gap-5 items-center mb-4">
                <Typography variant={"h5"} className={"font-bold capitalize"}>{
                    info?.scanTitle
                }</Typography>
            </div>
            <div className={`${styles.overview}`}>
                <div className={styles.background}></div>
                <div className="flex items-center justify-between space-x-4">
                    <div>
                        <Typography variant="h9" className="font-semibold">
                            Scan Initiated By
                            <span className="text-secondary-700 font-bold pl-2">{`${info?.scanInitiatedBy?.firstName} ${info?.scanInitiatedBy?.lastName}`}</span>
                        </Typography>
                    </div>
                    <div>|</div>
                    <div className="pr-8">
                        <Typography variant="h9" className="font-semibold">
                            Asset
                            <span className="text-secondary-700 font-bold pl-2">{info?.assetName}</span>
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mt-5 gap-x-2.5">
                <div className={"flex-1"}>
                    <Typography variant={"body2"} className={"!text-bold my-2"}>Total Findings</Typography>
                    <div className='border border-1 border-secondary-700 shadow-lg rounded-lg p-4 text-center'>
                        <div className="flex flex-wrap gap-x-2.5">
                            <div className={"flex-1 align-center"}>
                                <Typography variant={"h3"} className={"!text-bold"}>{Number(info?.confirmedTotalIssues || 0) + Number(info?.igTotalIssues || 0) + Number(info?.potentialTotalIssues || 0)}</Typography>
                            </div>
                            <div className={"flex-1"}>
                                <div className='confirmed-box rounded-lg p-4 text-center'>
                                    <Typography variant={"h6"} className={"text-bold confirmed-text"}>{Number(info?.confirmedTotalIssues || 0)}</Typography> 
                                    <Typography variant={"body5"} className={"text-bold confirmed-text"}>Confirmed</Typography>   
                                </div>
                            </div>
                            <div className={"flex-1"}>
                                <div className='potential-box rounded-lg p-4 text-center'>
                                    <Typography variant={"h6"} className={"text-bold potential-text"}>{Number(info?.potentialTotalIssues || 0)}</Typography> 
                                    <Typography variant={"body5"} className={"text-bold potential-text"}>Potential</Typography>   
                                </div>
                            </div>
                            <div className={"flex-1"}>
                                <div className='ig-box rounded-lg p-4 text-center'>
                                    <Typography variant={"h6"} className={"text-bold ig-text"}>{Number(info?.igTotalIssues || 0)}</Typography> 
                                    <Typography variant={"body5"} className={"text-bold ig-text"}>Information Gathering</Typography>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mt-5 gap-x-2.5">
                <div className={"flex-1"}>
                    <Typography variant={"body2"} className={"!text-bold my-2"}>Scan start date</Typography>
                    <div className='shadow-md rounded-lg p-4 text-center'>
                        <Typography variant={"h6"} className={"!text-bold text-secondary-700"}>{moment(info?.scanStartDatetime).format("MM/DD/YY")}</Typography> 
                        <Typography variant={"body5"} className={"!text-bold "}>{moment(info?.scanStartDatetime).format("hh:mm A")}</Typography>   
                    </div>
                </div>
                <div className={"flex-1"}>
                    <Typography variant={"body2"} className={"!text-bold my-2"}>Scan end date</Typography>
                    <div className='shadow-md rounded-lg p-4 text-center'>
                        <Typography variant={"h6"} className={"!text-bold text-secondary-700"}>{moment(info?.scanEndDateTime).format("MM/DD/YY")}</Typography> 
                        <Typography variant={"body5"} className={"!text-bold "}>{moment(info?.scanEndDateTime).format("hh:mm A")}</Typography>   
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mt-5 gap-x-2.5">
                <div className={"flex-1"}>
                <div className='shadow-md rounded-lg p-4'>
                    <Typography variant={"body2"} className={"!text-bold mt-2"}>Option Profile</Typography>
                    <Typography variant={"h6"} className={"!text-bold text-secondary-700 mt-1"}>{info?.optionProfileName}</Typography>
                    <Typography variant={"body2"} className={"!text-bold mt-2 break-words"}>{info?.optionProfileDescription}</Typography>
                </div> 
                </div>

            </div>
            <div className="flex flex-wrap mt-5 gap-x-2.5">
                <div className={"flex-1"}>
                <div onClick={() => handleTabClick('total', info?.totalIps)} className={`shadow-md ${info?.totalIps ? 'hover:cursor-pointer' : ''} rounded-lg p-4`}>
                    <div className='flex text-center py-4 items-center justify-center'>
                    <Typography variant={"h4"} className={`!text-bold`}>{info?.totalIps || 0}</Typography>   
                    <Typography variant={"h8"} className={`text-bold pl-4`}>Total Hosts</Typography>       
                    </div>
                </div>
                </div>
                <div className={"flex-1"}>
                <div onClick={() => handleTabClick('active', info?.totalHostsAlive)} className={`shadow-md ${info?.totalHostsAlive ? 'hover:cursor-pointer' : ''} rounded-lg p-4 active-hosts`}>
                    <div className='flex text-center py-4 items-center justify-center'>
                        <Typography variant={"h4"} className={`!text-bold`}>{info?.totalHostsAlive || 0}</Typography>   
                        <Typography variant={"h8"} className={`text-bold pl-4`}>Hosts Active</Typography>       
                    </div>
                </div>
                </div>
                <div className={"flex-1"}>
                <div onClick={() => handleTabClick('inactive', info?.totalDeadIps)} className={`shadow-md ${info?.totalDeadIps ? 'hover:cursor-pointer' : ''} rounded-lg p-4 inactive-hosts`}>
                    <div className='flex text-center py-4 items-center justify-center'>
                        <Typography variant={"h4"} className={`!text-bold`}>{info?.totalDeadIps || 0}</Typography>   
                        <Typography variant={"h8"} className={`text-bold pl-4`}>Hosts Inactive</Typography>       
                    </div>
                </div>
                </div>
            </div>
            <div className="tab-content">
                {activeTab === 'total' && renderHosts(info.ipCsv, info?.ipRanges)}
                {activeTab === 'active' && renderHosts(info.hostsAliveIpsCsv, info?.hostsAliveIpsRanges, 'active')}
                {activeTab === 'inactive' && renderHosts(info.deadIpsCsv, info?.deadIpsRanges, 'inactive')}
            </div>
        </div>
    );
};

export default VulnerabilityAssessment;
