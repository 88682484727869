import React from 'react';

const PersonIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" className={"inline-block"}
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.5031 4C8.93868 4 6.85264 6.08608 6.85264 8.65052C6.85264 10.4938 7.93318 12.0839 9.49171 12.8354C9.47992 12.8371 9.46774 12.8379 9.45595 12.8395C9.20979 12.8737 8.96659 12.9143 8.72817 12.9608C8.48964 13.007 8.25595 13.0591 8.02744 13.1172C7.79868 13.1755 7.57528 13.2398 7.35886 13.3094C7.14244 13.3788 6.93242 13.4537 6.73006 13.5339C6.5277 13.6141 6.33352 13.7 6.14707 13.7905H6.14653C5.96011 13.8809 5.78266 13.9763 5.61391 14.0764C5.44515 14.1765 5.28578 14.2813 5.13631 14.3905C4.98711 14.4996 4.84769 14.6132 4.71912 14.7309C4.59041 14.8487 4.47215 14.9703 4.3658 15.0959V15.0966C4.25973 15.2222 4.16544 15.3515 4.08335 15.4844C4.00125 15.617 3.9312 15.753 3.87482 15.8917V15.8922C3.81844 16.0314 3.77518 16.1735 3.74652 16.3171C3.71772 16.4604 3.70312 16.6053 3.70312 16.7501V20.5002C3.70312 20.5798 3.73473 20.6561 3.79098 20.7124C3.84723 20.7686 3.92357 20.8003 4.00312 20.8003H19.0031C19.0827 20.8003 19.159 20.7686 19.2153 20.7124C19.2715 20.6561 19.3031 20.5798 19.3031 20.5002V16.7501C19.3031 16.6473 19.2959 16.5446 19.2814 16.4424C19.2611 16.2982 19.2262 16.1553 19.1778 16.0147C19.1293 15.8742 19.0669 15.7365 18.992 15.6016V15.6011C18.917 15.4662 18.8296 15.3351 18.73 15.2073C18.6305 15.0794 18.5196 14.9553 18.3972 14.8352C18.2751 14.7152 18.1422 14.5989 17.9988 14.4872H17.9983C17.8548 14.3755 17.7015 14.2683 17.5384 14.1655C17.375 14.0625 17.2019 13.9642 17.0203 13.8708C16.8389 13.7774 16.6494 13.6887 16.4514 13.6053H16.4509C16.2529 13.5221 16.0475 13.4442 15.8349 13.3716H15.8344C15.6221 13.2987 15.4031 13.231 15.1781 13.1694C14.9529 13.108 14.7219 13.0522 14.4861 13.0024C14.2502 12.9527 14.0096 12.9089 13.7654 12.8711C13.6809 12.8581 13.594 12.85 13.5088 12.8384C15.0706 12.0883 16.1536 10.4964 16.1536 8.65077C16.1536 6.0863 14.0676 4.00025 11.5031 4.00025L11.5031 4Z"
                  fill="white"/>
        </svg>
    );
};

export default PersonIcon;
