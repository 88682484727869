import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';

const MuiSwitch = props => {
    const { checked, disabled, ...rest } = props;

    return (
        <Switch
            checked={checked}
            disabled={disabled}
            disableFocusRipple={true}
            disableRipple={true}
            disableTouchRipple={true}            
            {...rest}            
            className={"switch"}
        />
    );
};

MuiSwitch.propTypes = {    
    checked: PropTypes.bool,
    disabled: PropTypes.bool
};

export default MuiSwitch;
