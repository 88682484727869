import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getItemType } from '../../utils/constants/severity';
import Typography from '../Typography';

const CustomTooltip = (props) => {
  const { active, payload, label, type } = props; 
  if (active && payload && payload.length) {
    return (
      <div className="custom-graph-tooltip">
        {
          type === 'insight3' ?
            <>
              <p className="label">{`Pentest: ${Number(payload[0].payload.pentestCount) > 0 ? `${payload[0].payload.pentestCount} days` : '0 day'}`}</p>
              <p className="label">{`VA: ${Number(payload[0].payload.vaCount) > 0 ? `${payload[0].payload.vaCount} days` : '0 day'}`}</p>
            </>
          :
            <>
              <p className="label">{`Pentest: ${payload[0].payload.pentestCount}`}</p>
              <p className="label">{`VA: ${payload[0].payload.vaCount}`}</p>
            </>
        }
      </div>
    );
  }

  return null;
};

const StackedBar = (props) => {
  const { 
    type,
    graphData, 
    colors = [],
  } = props;

  return (
      <div className="flex flex-row h-[250px] stacked-bar">
        <div className="graph-container">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" style={{ fontSize: "14px", textTransform: 'capitalize' }} interval={0}/>
              <YAxis style={{ fontSize: "14px", textTransform: 'capitalize' }} />
              <Tooltip cursor={{fill: 'transparent'}} content={<CustomTooltip type={type} />} />
              <Bar barSize={30} dataKey="pentestCount" label="Pentest Count" stackId="a" fill="#228EBC" />
              <Bar barSize={30} dataKey="vaCount" label="VA Count" stackId="a" fill="#C745F4" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="label">
            <div className='flex items-center flex-[100%] flex-align-center'>
                <div className='flex flex-col h-[50px]'>
                    {
                        graphData?.map((item, index) => {
                          const itemType = getItemType(item);
                          return (
                              <div className="flex items-center flex-[100%]" key={index}>
                                  <div className="inline-block w-6 h-1 rounded-[10px] align-middle mr-2.5"
                                      style={{background: colors[itemType]?.color}}></div>
                                  <Typography variant={"caption"} className="capitalize"> 
                                      <span className='inline-block capitalize'>{colors[itemType]?.name}</span>
                                  </Typography>
                              </div>
                          )
                        })
                    }
                </div>
            </div>
        </div>
      </div>
  );
}

export default StackedBar;