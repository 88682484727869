import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import {
    CUSTOMER_DETAILS, EXISTING_ASSETS, LIST_ALL_PENTEST_ASSETS_REVIEW,
    LIST_CUSTOMER_TEAM,
    LIST_CUSTOMER_TEAM_BY_ROLE,
    LIST_CUSTOMER_TEAM_INVITES, PENTEST_SUCCESS_TEAM, PENTESTS, YOUR_PENTESTS, PENTEST_STATUS, LIST_ALL_CUSTOMER_TEAM, ASSETS, ALL_BLOCKERS, BANNER_DETAILS
} from "./utils/constants";


// queries
export const useListCustomerTeam = (pentest_id, role) => {
    return useQuery([LIST_CUSTOMER_TEAM, pentest_id, { role }], async () => {
        let res = await axios.get(`/pentest/customer_team/list?pentest_id=${pentest_id}&role=${role}`)
        return res.data
    })
}
export const useListCustomerTeamByRole = (pentest_id) => {
    return useQuery([LIST_CUSTOMER_TEAM_BY_ROLE, pentest_id], async () => {
        let res = await axios.get(`/pentest/customer_team/team/${pentest_id}`)
        return res.data
    })
}

export const useListAllTeamByCompanyId = (pentest_id, role) => {
    return useQuery([LIST_ALL_CUSTOMER_TEAM, pentest_id, { role }], async () => {
        let res = await axios.get(`/pentest/customer_team/list_all_team?pentest_id=${pentest_id}&role=${role}`)
        return res.data
    })
}

export const useListAllTeamByPentestId = (pentest_id) => {
    return useQuery([LIST_ALL_CUSTOMER_TEAM, pentest_id], async () => {
        let res = await axios.get(`/pentest/customer_team/list_team_by_pentestid/${pentest_id}`)
        return res.data
    })
}

export const useListCustomerTeamInvites = (pentest_id) => {
    return useQuery([LIST_CUSTOMER_TEAM_INVITES, pentest_id], async () => {
        let res = await axios.get(`/pentest/customer_team/${pentest_id}/list_invites`)
        return res.data
    })
}

export const usePentestById = (pentest_id) => {
    return useQuery([PENTESTS, pentest_id], async () => {
        let res = await axios.get(`/pentest/${pentest_id}`)
        return res.data
    }, {
        enabled: !!pentest_id
    })
}

// mutations
export const useMutateinvite = (role, options) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/customer_team/${role}/invite`, data)
        return res.data
    }, {
        onSuccess: async () => {
            queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_INVITES)
            await queryClient.invalidateQueries(LIST_CUSTOMER_TEAM)
            await queryClient.invalidateQueries(LIST_ALL_CUSTOMER_TEAM)
        },
        ...options
    })
}

export const useMutateInvitationCancel = (options) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/customer_team/${data.pentest_id}/cancel_invite/${data.invite_id}`)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_INVITES)
            queryClient.invalidateQueries(LIST_CUSTOMER_TEAM)
        },
        ...options
    })
}

export const useMutatePentestManagerOrOverseers = (isOverSeer = false, options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`/pentest/customer_team/${isOverSeer ? "pentest_overseer" : "pentest_manager"}/add`, data)
        return res.data
    }, options)
}

export const useMutateUpdatePentestManager = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`pentest/customer_team/pentest_manager/update`, data)
        return res.data
    }, options)
}

export const useMutatePentestAssetPoc = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`pentest/customer_team/pentest_asset_poc/add`, data)
        return res.data
    }, options)
}

export const useMutatePentestAssetPocDelete = (options) => {
    return useMutation(async (data) => {
        let res = await axios.post(`pentest/customer_team/pentest_asset_poc/delete`, data)
        return res.data
    }, options)
}

export const useActivatePentest = (options) => {
    return useMutation(async (pentest_id) => {
        let res = await axios.put(`/pentest/${pentest_id}/activate`)
        return res.data
    }, options)
}

export const useDeleteCustomerTeam = (pentest_id, options) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/customer_team/${pentest_id}/team/delete`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(LIST_CUSTOMER_TEAM_BY_ROLE)
            await queryClient.invalidateQueries(CUSTOMER_DETAILS)
        },
        ...options
    })
}

export const useSkipPentestStep = (pentest_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (step) => {
        let res = await axios.post(`/pentest/${pentest_id}/skip_pentest_step`, { step })
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries()
        }
    })
}


export const usePentestSuccessTeam = (options = {}) => {
    const {
        company_id = null,
        pentest_id = null,
        pentest_asset_id = null,
        finding_id = null,
        enabled
    } = options
    let dependencies = { company_id, pentest_id, pentest_asset_id, finding_id }
    return useQuery([PENTEST_SUCCESS_TEAM, dependencies], async () => {
        let res = await axios.get(`pentest/pentest_success_team${((company_id && `?company_id=${company_id}`) || "") +
            ((finding_id && (`${company_id ? "&" : "?"}finding_id=${finding_id}`)) || "") +
            ((pentest_id && (`${company_id ? "&" : "?"}pentest_id=${pentest_id}`)) || "") +
            ((pentest_asset_id && (`${company_id ? "&" : "?"}pentest_asset_id=${pentest_asset_id}`)) || "")
            }`)
        return res.data
    }, {
        enabled
    })
}

export const useDeletePentestAsset = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const res = await axios.put(`/pentest/asset/delete_pentest_assets`, data)
        return res?.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PENTESTS)
            await queryClient.invalidateQueries(EXISTING_ASSETS)
            await queryClient.invalidateQueries(LIST_ALL_PENTEST_ASSETS_REVIEW)
        }
    })
}

export const useDeletePentestAssetRequest = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const res = await axios.put(`/pentest/asset/pentest_asset/request_delete/`, data)
        return res?.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PENTESTS)
            await queryClient.invalidateQueries(EXISTING_ASSETS)
            await queryClient.invalidateQueries(LIST_ALL_PENTEST_ASSETS_REVIEW)
        }
    })
}


export const useEditPentest = (pentest_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/${pentest_id}/edit`, data)
        return res?.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(PENTESTS)
        }
    })
}

export const useDeleteDraftPentest = () => {
    const queryClient = useQueryClient()
    return useMutation(async (pentest_id) => {
        let res = await axios.delete(`/pentest/draft/${pentest_id}`)
        return res?.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(YOUR_PENTESTS)
        }
    })
}

export const useEditPentestLatestStatus = (pentest_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/pentest_latest_status/${pentest_id}`, data)
        return res.data
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(ASSETS)
            queryClient.invalidateQueries(PENTEST_STATUS)
            queryClient.invalidateQueries(ALL_BLOCKERS)
            queryClient.invalidateQueries(BANNER_DETAILS)
        }
    })
}

export const useUpdatePentestIteration = (pentest_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.put(`/pentest/update_pentest_iteration/${pentest_id}`, data)
        return res.data;
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(PENTESTS)
        }
    });
}