import React, { useContext } from 'react';
import ReactTable from '../../../../../../components/Table';
import StackedBar from '../../../../../../components/Widgets/StackedBarChart';
import Typography from '../../../../../../components/Typography';
import { Link } from 'react-router-dom';
import { Parser } from "html-to-react";
import { LayoutContext } from '../../../../../../Providers/LayoutProvider';
import { CTEMInsight1001Colors } from '../../../../../../utils/constants/asset';

const Insight1 = (props) => {
    const { description, data } = props;
    const htmlParser = new Parser();
    const { mainUrl } = useContext(LayoutContext);

    const pentestColumns = [
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: false,
            className: 'w-[320px]',
            Cell: props => {
                const [findingTitle, findingId] = props?.value;
                return (
                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/pentests/all_findings/${findingId}/dashboard`}>
                        {findingTitle}
                    </Link>
                )
            }
        },
        {
            Header: 'Asset',
            accessor: 'asset',
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: false,
            Cell: props => {
                const status = props?.value;
                return (  
                    status === 're-opened' ?
                        <span className='text-camel-case'>
                            Re-Opened
                        </span>
                    :
                        <span className='text-camel-case'>
                            {status.replace("_", " ")}
                        </span>
                    
                )
            }
        }
    ]

    const vaColumns = [
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: false,
            className: 'w-[280px]',
            Cell: props => {
                const [findingTitle, findingId] = props?.value;
                return (
                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_va/finding/${findingId}/dashboard`}>
                        {findingTitle}
                    </Link>
                )
            }
        },
        {
            Header: 'Asset',
            accessor: 'asset',
        },
        {
            Header: 'IP',
            accessor: 'ip',
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: false,
            Cell: props => {
                const status = props?.value;
                return (  
                    status === 're-opened' ?
                        <span className='text-camel-case'>
                            Re-Opened
                        </span>
                    :
                        <span className='text-camel-case'>
                            {status.replace("-", " ")}
                        </span>
                    
                )
            }
        }
    ];

    const pentestTableData = data?.pentest?.map((item, index) => {
        return ({
            title: [item?.findingTitle, item?.findingId],
            asset: item?.assetName,
            status: item?.status
        })
    })

    const vaTableData = data?.va?.map((item, index) => {
        return ({
            title: [item?.findingTitle, item?.findingId],
            asset: item?.assetName,
            ip: item?.ip,
            status: item?.status
        })
    })

    const graphData = [];
    data?.graphData?.map((graph) => {
        graphData.push({
            name: graph?.criticality.replace("_", " "),
            pentestCount: graph?.pentestFindingCount,
            vaCount: graph?.vaFindingCount,
        });
    });

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph stackedbar-container'>
                    {
                        data?.graphData &&
                            <StackedBar 
                                graphData={graphData} 
                                colors={CTEMInsight1001Colors}
                            />
                    }
                </div>
            </div>
            <div className='lower-section'>
                <div className='pentest mr-5'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Pentests</Typography>
                    </div>
                    {
                        data?.pentest &&
                            <ReactTable
                                columns={pentestColumns}
                                data={pentestTableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
                <div className='va'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Vul. Assessments</Typography>
                    </div>
                    {
                        data?.pentest &&
                            <ReactTable
                                columns={vaColumns}
                                data={vaTableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight1;