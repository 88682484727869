import React from 'react';
import PropTypes from 'prop-types';
import {useImage} from "../../../hooks/misc";
import {Avatar, AvatarGroup} from "@mui/material";
import {getAvatarString} from "../../../actions/commonActions";

const AvatarGroups = ({users, max = 3}) => {
    return (
        <div className={"float-left"}>
            <AvatarGroup
                max={max}
                classes={{
                    avatar: 'avatar'
                }}
            >
                {
                    users?.map((item, index) => <AvatarImage user={item} key={index}/>)
                }
            </AvatarGroup>
        </div>
    );
};

AvatarGroups.propTypes = {};

export const AvatarImage = ({user, ...props}) => {
    let name = `${user?.first_name} ${user.last_name}`
    let url = useImage(user?.profile_picture)
    return (
        <>
            {
                url ?
                    <Avatar title={name} {...props} src={url}/> :
                    <Avatar title={name} {...props} children={getAvatarString(name)}/>
            }
        </>
    )
}

export default AvatarGroups;
