import React, {createContext} from "react";

export const LayoutContext = createContext()


const LayoutProvider = ({value, children}) => {
    return (
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    );
};

export default LayoutProvider;
