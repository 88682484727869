import React, { useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closePrioritizedFindingModal } from '../../../../../redux/reducers/dashboard';
import Modal from '../../../../../components/MuiComponents/Modal';
import moment from 'moment/moment';
import Typography from '../../../../../components/Typography';
import ReactTable from '../../../../../components/Table'
import { Link } from 'react-router-dom';
import { LayoutContext } from '../../../../../Providers/LayoutProvider';
import { get2DigitFormattedNumber } from '../../../../../actions/commonActions';
import { useCTEMPrioritizedFindingList } from '../../../../../hooks/ctem';
import MainLoader from '../../../../../components/Loader/MainLoader';
import { getRiskColor } from '../../../../../utils/constants/findings';

const PrioritizedFindingModal = () => {
    const { prioritizedFindingModal: { companyId, title } } = useSelector(state => state.dashboard);
    const { mainUrl } = useContext(LayoutContext);
    const { data, isFetching } = useCTEMPrioritizedFindingList(companyId);

    const pentestFindings = data?.prioritizedFindingList?.pentestFindings;
    const vaFindings = data?.prioritizedFindingList?.vaFindings;

    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closePrioritizedFindingModal())
    }

    const pentestColumns = [
        {
            Header: '#',
            accessor: 'index',
            className: 'w-14',
            disableSortBy: false,
        },
        {
            Header: 'Finding',
            accessor: 'finding',
            disableSortBy: false,
            Cell: props => {
                const [findingTitle, findingId] = props?.value;
                return (
                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/pentests/all_findings/${findingId}/dashboard`}>
                        {findingTitle}
                    </Link>
                )
            }
        },
        {
            Header: 'Severity',
            accessor: 'severity',
            disableSortBy: false,
            Cell: props => {
                const severity = props?.value;
                return (
                    <span className='text-camel-case'>{severity}</span>
                )
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: false,
            Cell: props => {
                const status = props?.value;
                return (  
                    status === 're-opened' ?
                        <span className='text-camel-case'>
                            Re-Opened
                        </span>
                    :
                        <span className='text-camel-case'>
                            {status.replace("_", " ")}
                        </span>
                    
                )
            }
        },
        {
            Header: 'Risk score',
            accessor: 'riskScore',
            Cell: props => (
            <span className={`flex ${getRiskColor(props?.value)}`}>{props?.value}</span>)
        },
        {
            Header: 'Asset',
            accessor: 'asset',
        },
        {
            Header: 'Pentest',
            accessor: 'pentest',
        },
        {
            Header: 'Logged On',
            accessor: 'loggedOn',
            Cell: props => {
                const loggedOn = props?.value;
                return (
                    <span>{moment(loggedOn).format('MM/DD/YYYY')}</span>
                )
            }
        }
    ]

    const vaColumns = [
        {
            Header: '#',
            accessor: 'index',
            className: 'w-14',
            disableSortBy: false,
        },
        {
            Header: 'Finding',
            accessor: 'finding',
            disableSortBy: false,
            Cell: props => {
                const [findingTitle, findingId] = props?.value;
                return (
                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/all_va/finding/${findingId}/dashboard`}>
                        {findingTitle}
                    </Link>
                )
            }
        },
        {
            Header: 'Severity',
            accessor: 'severity',
            disableSortBy: false,
            Cell: props => {
                const severity = props?.value;
                return (
                    <span className='text-camel-case'>{severity}</span>
                )
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: false,
            Cell: props => {
                const status = props?.value;
                return (
                    <span className='text-camel-case'>{status}</span>
                )
            }
        },
        {
            Header: 'Risk score',
            accessor: 'riskScore',
            className: 'th-align-center w-[120px]',
            Cell: props => (
            <span className={`flex align-center ${getRiskColor(props?.value)}`}>{props?.value}</span>)
        },
        {
            Header: 'IP',
            accessor: 'ip',
        },
        {
            Header: 'Port',
            accessor: 'port',
        },
    ];

    const pentestTableData = pentestFindings?.map((item, index) => {
        return ({
            index: get2DigitFormattedNumber(index + 1),
            finding: [item?.findingName, item?.findingId],
            severity: item?.severity,
            status: item?.status,
            asset: item?.assetName,
            pentest: item?.pentestName,
            riskScore: item?.riskScore,
            loggedOn: item?.loggedOn
        })
    })

    const vaTableData = vaFindings?.map((item, index) => {
        return ({
            index: get2DigitFormattedNumber(index + 1),
            finding: [item?.findingName, item?.findingId],
            severity: item?.severity,
            status: item?.status,
            riskScore: item?.riskScore,
            ip: item?.ip,
            port: item?.port,
        })
    })

    return (
        <Modal
            open={true}
            maxWidth={"lg"}
            fullWidth={true}
            dialogTitle={<>
               <Typography variant={"h5"} className={`!text-white`}>
                    <span>{title}</span>
                </Typography>
            </>}
            zeroPadding={true}
            onClose={closeModal}
        >
            <div className="text-left">
                {(isFetching) && <MainLoader/>}
                <div className="ctem-insight-container py-4">
                    {
                        !isFetching &&
                            <div className={"max-h-[500px] px-4 overflow-auto"}>
                                <div className='pentest p-4 background-white border-radius-10 mb-4'>
                                    <div className='xl:col-span-12 heading mb-4'>
                                        <Typography variant={"h7"} className={"text-headings"}>Pentests</Typography>
                                    </div>
                                    <ReactTable
                                        columns={pentestColumns}
                                        data={pentestTableData || []}
                                        className={"table lg:table-fixed middle-aligned"}
                                        headerClassName={"background rounded sticky z-[5] top-0"}
                                        bodyClassName={"text-headings"}
                                    />  
                                </div>
                                <div className='note-prioritized-finding'>
                                    <p>Note: In the CTEM dashboard, Vul Assessment severities (from Qualys) are mapped with platform severities, for prioritization, as below:</p>
                                    <p className='mt-2'>{`Urgent -> Critical`}</p>
                                    <p>{`Critical -> High`}</p>
                                </div>
                                <div className='va p-4 background-white border-radius-10 mt-5'>
                                    <div className='xl:col-span-12 heading mb-4'>
                                        <Typography variant={"h7"} className={"text-headings"}>Vul. Assessments</Typography>
                                    </div>
                                    <ReactTable
                                        columns={vaColumns}
                                        data={vaTableData || []}
                                        className={"table lg:table-fixed middle-aligned"}
                                        headerClassName={"background rounded sticky z-[5] top-0"}
                                        bodyClassName={"text-headings"}
                                    />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </Modal>
    );
};

export default PrioritizedFindingModal;
