import React from 'react';
import PropTypes from 'prop-types';

const ApiIcon = ({className}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={`inline-block ${className}`}
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.3999 3H2.62272C1.71863 3 1 3.74182 1 4.62272V19.8763C1 20.7804 1.71863 21.499 2.62272 21.499H21.3767C22.2808 21.499 22.9995 20.7804 22.9995 19.8763V4.62272C23.0227 3.71863 22.2808 3 21.3999 3ZM7.95452 4.66908C8.34861 4.66908 8.64997 4.99363 8.64997 5.36454C8.64997 5.75863 8.32543 6.05999 7.95452 6.05999C7.56043 6.05999 7.25907 5.73544 7.25907 5.36454C7.25907 4.97045 7.56043 4.66908 7.95452 4.66908ZM5.8218 4.66908C6.21589 4.66908 6.51725 4.99363 6.51725 5.36454C6.51725 5.75863 6.19271 6.05999 5.8218 6.05999C5.42771 6.05999 5.12635 5.73544 5.12635 5.36454C5.12635 4.97045 5.45089 4.66908 5.8218 4.66908ZM3.68908 4.66908C4.08317 4.66908 4.38453 4.99363 4.38453 5.36454C4.38453 5.75863 4.05999 6.05999 3.68908 6.05999C3.29499 6.05999 2.99363 5.73544 2.99363 5.36454C2.99363 4.97045 3.31817 4.66908 3.68908 4.66908ZM20.9827 19.3431C20.9827 19.4127 20.9363 19.459 20.8668 19.459H3.1559C3.08636 19.459 3.03999 19.4127 3.03999 19.3431V7.79862H20.9595V19.3431H20.9827Z"
                fill="#228EBC"/>
            <path
                d="M8.44101 16.2601C8.60328 16.4223 8.81192 16.5151 9.04374 16.5151C9.22919 16.5151 9.43783 16.4455 9.6001 16.3064C9.92464 16.0051 9.94782 15.4951 9.64646 15.1705L8.09329 13.5014L9.64646 11.8324C9.94782 11.5078 9.92464 10.9978 9.6001 10.6964C9.27555 10.3951 8.76556 10.4183 8.46419 10.7428L6.40102 12.9451C6.12284 13.2464 6.12284 13.7333 6.40102 14.0346L8.44101 16.2601Z"
                fill="#228EBC"/>
            <path
                d="M14.4454 16.3056C14.6077 16.4447 14.7931 16.5142 15.0018 16.5142C15.2104 16.5142 15.4422 16.4215 15.6045 16.2592L17.6445 14.0338C17.9227 13.7324 17.9227 13.2456 17.6445 12.9442L15.6045 10.7188C15.3031 10.3942 14.7931 10.371 14.4686 10.6724C14.144 10.9738 14.1209 11.4838 14.4222 11.8083L15.9754 13.4774L14.4222 15.1465C14.0977 15.4942 14.0977 16.0042 14.4454 16.3056Z"
                fill="#228EBC"/>
            <path
                d="M11.2701 17.5349C11.3164 17.5349 11.3628 17.558 11.4323 17.558C11.8032 17.558 12.151 17.2799 12.2205 16.9089L13.4028 10.6499C13.4955 10.2094 13.1942 9.79215 12.7537 9.69942C12.3132 9.60669 11.896 9.90806 11.8032 10.3485L10.621 16.5844C10.5514 17.0249 10.8296 17.4421 11.2701 17.5349Z"
                fill="#228EBC"/>
        </svg>
    );
};

ApiIcon.propTypes = {
    className: PropTypes.string
};

export default ApiIcon
