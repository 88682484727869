import React from 'react';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import MailIcon from '@mui/icons-material/Mail';

const Footer = props => {
    let {positionType = 'fixed'} = props;

    return (
        <>
            <div className="clear-both h-[48px]"></div>
            <footer className={"w-full sm:bottom-0"} style = {{position: positionType}}>
                {/* <h6><PhoneAndroidIcon/><a href={"tel:+404-383-676"}>+404-383-676</a></h6> */}
                <h6><MailIcon/><a target={"_blank"} rel={"noreferrer"}
                                  href={"mailto:hello@siemba.io"}>hello@siemba.io</a>
                </h6>
                <h6 className={'sm:float-right'}>&copy; {new Date().getFullYear()} by Siemba Inc.</h6>
            </footer>
        </>
    );
};

// Footer.defaultProps = {
//     position: 'fixed',
// };


export default Footer;
