import React from 'react';

const EditIcon = ({className, pathProps, ...props}) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" className={`inline-block ${className}`} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2_3657)">
                <path
                    d="M16.9934 -0.00969143C16.8367 -0.0068789 16.6874 0.0570047 16.5773 0.168433L14.7011 2.04345L19.5398 6.88323L21.4184 5.00461C21.5301 4.89225 21.5928 4.7401 21.5928 4.58166C21.5928 4.42309 21.5301 4.27095 21.4184 4.15858L17.4269 0.168399C17.3125 0.0526847 17.156 -0.0116011 16.9934 -0.00972571L16.9934 -0.00969143ZM13.8527 2.89188L4.61854 12.1264C4.50805 12.2386 4.44618 12.3897 4.44618 12.5471L4.42863 16.5561C4.42796 16.716 4.49131 16.8697 4.60435 16.9827C4.71752 17.0958 4.871 17.1591 5.03103 17.1585L9.03868 17.1408H9.03855C9.19672 17.1395 9.34792 17.0759 9.45934 16.9638L18.6925 7.73064L13.8527 2.89188ZM2.99306 18.0092C2.78185 18.0138 2.58873 18.1293 2.4844 18.3129C2.3802 18.4967 2.3802 18.7217 2.4844 18.9054C2.58873 19.0892 2.78185 19.2045 2.99306 19.2092H18.9647C19.1759 19.2045 19.369 19.0892 19.4733 18.9054C19.5775 18.7217 19.5775 18.4966 19.4733 18.3129C19.369 18.1293 19.1759 18.0138 18.9647 18.0092H2.99306Z"
                    fill="black" fillOpacity="0.6" {...pathProps}/>
            </g>
            <defs>
                <clipPath id="clip0_2_3657">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditIcon;
