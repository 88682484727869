import {useInfiniteQuery, useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import {
    ALL_FINDINGS,
    DRAFT_FINDINGS,
    FINDINGS,
    FINDINGS_STATS,
    FINDING_BREAKDOWN_CHART,
    FINDING_BREAKDOWN_STATUS_CHART,
    LIST_SIEMBA_ASSETS,
    ASSET_MANAGEMENT,
    VULNERABILITY_DATA,
    FINDINGS_HISTORY
} from "./utils/constants";
import {paginationAttr} from "../actions/commonActions";

//queries
export const useFindingsById = (id, from = '', options = {}) => {
    return useQuery([FINDINGS, id, from], async () => {
        let res = null
        if (from === 'GENPT') {
            res = await axios.get(`/genpt/findings/${id}`)
        } else {
            res = await axios.get(`pentest/findings/${id}`);
        }
        return res.data;
    }, {
        enabled: !!id,
        ...options
    })
}

export const useFindingsHistoryById = (id, options = {}) => {

    const {
        pageSize = 2
    } = options

    return useInfiniteQuery([FINDINGS_HISTORY, {id, pageSize}],  async ({pageParam = 1}) => {
        let res = await axios.get(`pentest/findings/${id}/status/justification${paginationAttr({
            page: pageParam, 
            pageSize
        })}`);
                    
        return res.data;
    }, {
        enabled: !!id,
        keepPreviousData: true,
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
    })
}

export const useFindingsStats = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null
    } = options
    return useQuery([FINDINGS_STATS, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`pentest/findings/dashboard/title${paginationAttr({
            pentest_id, pentest_asset_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}

//mutations
export const useCreateFindings = (options = {}) => {
    
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`pentest/findings/create_or_update_finding`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DRAFT_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)        
            await queryClient.invalidateQueries(FINDINGS_HISTORY)    
        },
        ...options
    })
}

//mutations
export const useUpdateFindingAttributes = (pentest_asset_id) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            pentest_asset_id,
            finding_data: data
        }
        let res = await axios.put(`pentest/findings/update_finding_attributes`, payload)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DRAFT_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS)
            await queryClient.invalidateQueries(ALL_FINDINGS)
            await queryClient.invalidateQueries(FINDINGS_STATS)
            await queryClient.invalidateQueries(LIST_SIEMBA_ASSETS)
            await queryClient.invalidateQueries(FINDINGS_HISTORY)
        },
    })
}

export const useUpdateQualysFindingAttributes = (vulnId, status) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        const payload = {
            vulnId,
            assetId: data?.assetId,
            companyId: data?.companyId,
            fromStatus: data?.fromStatus,
            toStatus: data?.toStatus,
            justification: data?.justification,
            uploadedImages: data?.uploadedImages,
            lastUpdateDateTime: data?.lastUpdateDateTime,
            createdAt: data?.createdAt,
            updatedAt: data?.updatedAt,
        };

        let res = await axios.put(`pentest/asset/update-vulnerabilities`, payload)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(VULNERABILITY_DATA)
        },
    })
}


export const useBreakdownFindings = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null,
        company_id = null
    } = options
    return useQuery([FINDING_BREAKDOWN_CHART, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`/dashboard/charts/severity_breakdown${paginationAttr({
            pentest_id, pentest_asset_id, company_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}


export const useBreakdownStatusFindings = (options = {}) => {
    const {
        pentest_id = null,
        pentest_asset_id = null,
        company_id = null
    } = options
    return useQuery([FINDING_BREAKDOWN_STATUS_CHART, pentest_id, pentest_asset_id], async () => {
        let res = await axios.get(`/dashboard/charts/status_breakdown${paginationAttr({
            pentest_id, pentest_asset_id, company_id
        })}`)
        return res.data;
    }, {
        ...options
    })
}

export const useDeleteFinding = () => {
    
    let queryClient = useQueryClient()
    return useMutation(async (finding_id) => {
        let res = await axios.put(`/pentest/findings/delete/${finding_id}`)
        return res;
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ALL_FINDINGS)
        }
    })
}


export const useSearchFindingsByAttributes = (options) => {
    return useMutation(async (data) => {
        let payload = {
            data: data
        }
        let res = await axios.post(`pentest/findings/search`, payload)
        return res.data
    }, options)
}


export const useQualysFindingsSearch = () => {
    return useMutation(async (data) => {
        const res = await axios.post('pentest/findings/qualys_search', data);
        return res.data;
    });
}

export const useUpdateCvssJustification = (findingId) => {

    return useMutation(async (data) => {
        let res = await axios.put(`pentest/findings/${findingId}/cvss/justification`, data)
        return res.data
    })
}

export const useDeleteCvssJustification = (params) => {

    return useMutation(async (data) => {
        let res = await axios.delete(`pentest/findings/${params?.pentestAssetId}/${params?.findingId}/cvss/justification`, data)
        return res.data
    })
}

export const useVulnerabilityDataByVulnId = (vuln_id) => {
    return useQuery([VULNERABILITY_DATA, vuln_id], async () => {
        let res = await axios.get(`/pentest/asset/vulnerability-data/?vulnId=${vuln_id}`)
        return res.data;
    }, {
        enabled: !!vuln_id
    })
}