import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

const ScrollToTop = () => {
    const {pathname, search} = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById("dashboardMainContent")?.scrollTo(0, 0)
    }, [pathname, search])
    return (
        <div>

        </div>
    );
};

export default ScrollToTop;
