import React from 'react';
import withPermissions from "../../../../hoc/withPermissions";
import { SIEMBA_ADMIN } from "../../../../utils/roles";
import AssetList from './AssetList';



const AssetManagement = () => {
    return (
        <div>
            <AssetList/>
        </div>
    );
};

export default withPermissions(AssetManagement, { roles: [SIEMBA_ADMIN] });