import React from 'react';

const CloudIcon = ({className}) => {
    return (
        <svg className={`inline-block ${className}`} width="26" height="26" viewBox="0 0 26 26" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4864_56758)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M22.8964 11.682C22.7623 7.92951 19.1438 5.38299 15.5922 6.38808C12.2417 1.36228 4.40145 3.43957 4.13365 9.53753C-2.1654 11.2798 -0.959004 20.7952 5.67493 20.7952H21.3552C26.6491 20.7952 27.7883 13.2901 22.8964 11.6818L22.8964 11.682Z"
                      fill="#228EBC"/>
            </g>
            <defs>
                <clipPath id="clip0_4864_56758">
                    <rect width="26" height="26" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default CloudIcon;
