import React from 'react';

const ReportDeliveredIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.37099 20.9998H13.6839C12.9114 20.2242 12.4243 19.1085 12.4243 17.8707C12.4243 15.536 14.1536 13.6372 16.2797 13.6372C16.5267 13.6372 16.7669 13.6639 17 13.7136V4.50534C17 3.67625 16.3841 3 15.6291 3H5.37105C4.61245 3 4 3.67625 4 4.50534V19.4945C4 20.3236 4.61242 20.9998 5.37099 20.9998ZM6.2583 5.74703H14.7417C14.9331 5.74703 15.0897 5.91897 15.0897 6.12912C15.0897 6.34315 14.9331 6.5112 14.7417 6.5112H6.2583C6.06692 6.5112 5.91033 6.34315 5.91033 6.12912C5.91033 5.91897 6.06692 5.74703 6.2583 5.74703ZM6.2583 8.09685H14.7417C14.9331 8.09685 15.0897 8.26879 15.0897 8.47894C15.0897 8.68909 14.9331 8.86102 14.7417 8.86102H6.2583C6.06692 8.86102 5.91033 8.68909 5.91033 8.47894C5.91033 8.26879 6.06692 8.09685 6.2583 8.09685ZM6.2583 10.4429H14.7417C14.9331 10.4429 15.0897 10.6149 15.0897 10.825C15.0897 11.039 14.9331 11.2071 14.7417 11.2071H6.2583C6.06692 11.2071 5.91033 11.039 5.91033 10.825C5.91033 10.6149 6.06692 10.4429 6.2583 10.4429ZM6.2583 12.7927H14.7417C14.9331 12.7927 15.0897 12.9647 15.0897 13.1748C15.0897 13.385 14.9331 13.5569 14.7417 13.5569H6.2583C6.06692 13.5569 5.91033 13.385 5.91033 13.1748C5.91033 12.9647 6.06692 12.7927 6.2583 12.7927ZM6.2583 15.1426H10.7714C10.9628 15.1426 11.1194 15.3106 11.1194 15.5247C11.1194 15.7348 10.9628 15.9067 10.7714 15.9067H6.2583C6.06692 15.9067 5.91033 15.7348 5.91033 15.5247C5.91033 15.3106 6.06692 15.1426 6.2583 15.1426ZM6.2583 17.4885H10.7714C10.9628 17.4885 11.1194 17.6604 11.1194 17.8706C11.1194 18.0807 10.9628 18.2527 10.7714 18.2527H6.2583C6.06692 18.2527 5.91033 18.0807 5.91033 17.8706C5.91033 17.6604 6.06692 17.4885 6.2583 17.4885Z"
                fill="#12AFB8"/>
            <circle cx="17" cy="18" r="4" fill="#12AFB8"/>
            <path
                d="M15.836 17.0393H14.9252C14.8402 17.0393 14.7734 17.1061 14.7734 17.1911V19.6199C14.7734 19.7049 14.8402 19.7717 14.9252 19.7717H15.836C15.9211 19.7717 15.9877 19.7049 15.9877 19.6199L15.9878 17.1912C15.9879 17.1061 15.9211 17.0394 15.836 17.0394L15.836 17.0393Z"
                fill="#E5E5E5"/>
            <path
                d="M20.2282 17.327C20.2282 17.0797 20.0583 16.8293 19.734 16.8293H18.2634C18.4734 16.4535 18.5352 15.9249 18.3892 15.5382C18.2819 15.2538 18.0769 15.0878 17.8116 15.0709L17.8073 15.0706C17.6346 15.06 17.4852 15.1893 17.4709 15.3616C17.433 15.7452 17.265 16.4237 17.0241 16.6646C16.8213 16.8675 16.6477 16.9524 16.3599 17.0931C16.3182 17.1135 16.2727 17.1357 16.2246 17.1597C16.2255 17.1702 16.226 17.1805 16.226 17.1912V19.5952C16.2607 19.6071 16.295 19.6189 16.3287 19.6305C16.8037 19.7942 17.2142 19.9355 17.8415 19.9355H19.0304C19.3549 19.9355 19.5247 19.6851 19.5247 19.4378C19.5247 19.3644 19.5098 19.2908 19.4803 19.2234C19.5883 19.204 19.683 19.152 19.753 19.0725C19.8326 18.9821 19.8764 18.862 19.8764 18.7342C19.8764 18.6611 19.8615 18.5875 19.8321 18.5203C20.0926 18.4753 20.2282 18.2517 20.2282 18.0307C20.2282 17.9025 20.1825 17.7734 20.0934 17.6789C20.1824 17.5844 20.2281 17.4553 20.2281 17.3271L20.2282 17.327Z"
                fill="#E5E5E5"/>
        </svg>
    );
};

export default ReportDeliveredIcon;
