import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeEASMKeyInsightsModal } from '../../../../../redux/reducers/dashboard';
import Modal from '../../../../../components/MuiComponents/Modal';
import Typography from '../../../../../components/Typography';
import MainLoader from '../../../../../components/Loader/MainLoader';
import { useEASMInsightDetails } from '../../../../../hooks/easm';
import Insight1 from './insightsContent/insight1';
import Insight2 from './insightsContent/insight2';
import Insight3 from './insightsContent/insight3';
import Insight4 from './insightsContent/insight4';
import Insight5 from './insightsContent/insight5';
import Insight6 from './insightsContent/insight6';
import Insight7 from './insightsContent/insight7';
import reactStringReplace from 'react-string-replace';

const EASMKeyInsightModal = () => {
    const { easmKeyInsightsModal: { insightId, title, description, companyId, count } } = useSelector(state => state.dashboard);
    const { data, isFetching } = useEASMInsightDetails(companyId, insightId);
    const insight = data && JSON.parse(data?.insight);

    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeEASMKeyInsightsModal())
    }

    return (
        <Modal
            open={true}
            maxWidth={"lg"}
            fullWidth={true}
            dialogTitle={<>
               <Typography variant={"h5"} className={`!text-white`}>
                    <span>{reactStringReplace(title, '{{COUNT}}', (match, i) => (
                            <span>{count}</span>
                        ))}</span>
                </Typography>
            </>}
            zeroPadding={true}
            onClose={closeModal}
        >
            <div className="text-left">
                {(isFetching) && <MainLoader/>}
                <div className="easm-insight-container py-5">
                    <div className={"max-h-[500px] px-5 overflow-auto"}>
                        <div className="flex flex-[100%]">
                            {
                                insightId === '1001' ?
                                    <Insight1 
                                        description={description}
                                        data={insight}
                                    />
                                :
                                    insightId === '1002' ?
                                        <Insight2
                                            description={description}
                                            data={insight}
                                        />
                                    :
                                        insightId === '1003' ?
                                            <Insight3 
                                                description={description}
                                                data={insight}
                                            />
                                        :
                                            insightId === '1004' ?
                                                <Insight4 
                                                    description={description}
                                                    data={insight}
                                                />
                                            :
                                                insightId === '1005' ?
                                                    <Insight5 
                                                        description={description}
                                                        data={insight}
                                                    />
                                                :
                                                    insightId === '1006' ?
                                                        <Insight6 
                                                            description={description}
                                                            data={insight}
                                                        />
                                                    :
                                                        insightId === '1007' ?
                                                            <Insight7 
                                                                description={description}
                                                                data={insight}
                                                            />
                                                        :
                                                            <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default EASMKeyInsightModal;
