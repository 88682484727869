import {createSlice} from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'auth',
    initialState: {
        openDrawer: false,
        expandDrawer: window.sessionStorage.getItem("drawerCollapsed") === "true" ? false : true,
        isMenuExpanded: false,
    },
    reducers: {
        setDrawer: (state, action) => {
            return {...state, openDrawer: action.payload}
        },
        setExpandDrawer: (state, action) => {
            return {...state, expandDrawer: action.payload}
        },
        setCommonInputs: (state, action) => {
            return {...state, ...action.payload}
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setDrawer, setCommonInputs, setExpandDrawer
} = commonSlice.actions

export default commonSlice.reducer
