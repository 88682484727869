import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemIcon, Popover } from '@mui/material';
import Button from '../Button';

const ITEM_HEIGHT = 48;

export default function LongMenu({options, menuClassName}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //console.log("options", options)

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
    {/* three dots icon */}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      {/* drop down menu */}
      <Popover
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        getContentAnchorEl={null}
        style={{
            position: 'absolute',
            right: '0',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            borderRadius: '8px',
            width: '18em',
          },
        }}
      >
        {options.map((option) => (
          //console.log("option", option)
          // if a btn has isGreyed property
          option.isGreyed ? (
          <MenuItem key={option.value}>
            <Button
              variant={"text"}
              size={"extra_small"}
              className={"!text-subtitle !text-headings !rounded-full"}>
                {option.icon}
            </Button>
            <span className={'text-subtitle'}>{option.label}</span>
          </MenuItem>
          ): ( 
          <MenuItem key={option.value} onClick={option.click}>
            <Button
              variant={"text"}
              size={"extra_small"}
              className={option.iconClassName}>
                {option.icon}
            </Button>
            <span className={option.labelClass}>{option.label}</span>
          </MenuItem>
          )
        ))}
      </Popover>
    </div>
  );
}