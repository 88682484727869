import React from 'react';

const InternalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={"inline-block"} fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.0105 19.0106H5.00635V7.00636H12.7787L14.7681 5H3.9947C3.43832 5 3 5.45525 3 5.9947V20.0053C3 20.5617 3.45524 21 3.9947 21H18.0052C18.5616 21 18.9999 20.5448 18.9999 20.0053L19 9.24867L17.0105 11.255L17.0105 19.0106Z"
                fill="#12AFB8"/>
            <path
                d="M10.692 9.82925C10.692 9.35604 10.3048 8.98324 9.84601 8.98324C9.38709 8.98324 9 9.37043 9 9.82925V13.8443C9 14.3175 9.38719 14.6903 9.84601 14.6903H13.8611C14.3343 14.6903 14.7071 14.3031 14.7071 13.8443C14.7071 13.3711 14.3199 12.9983 13.8611 12.9983H11.8965L21 3.60795C21.3298 3.27816 21.8298 2.43778 21.5 2.108C21.1702 1.77821 20.3298 2.17022 20 2.5L10.692 11.7938L10.692 9.82925Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default InternalIcon;
