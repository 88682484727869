import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs from '../../../../../components/Dashboard/Breadcumb';
import { LayoutContext } from '../../../../../Providers/LayoutProvider';
import { get2DigitFormattedNumber, getAllDataFromPages } from '../../../../../actions/commonActions';
import { useDraftFindings } from '../../../../../hooks/dashboard';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/Button';
import { setAddFindingsModal } from '../../../../../redux/reducers/dashboard';
import AllTableData from '../../../../../components/Dashboard/AllTableData';
import { useInView } from 'react-intersection-observer';
import MainLoader from '../../../../../components/Loader/MainLoader';

const DraftFindings = () => {

    const { mainUrl } = useContext(LayoutContext)

    const BREADCRUMB_DATA = [
        {
            title: "My Dashboard",
            url: `${mainUrl}/dashboard`
        },
        {
            title: "Draft Findings"
        }
    ]

    const [search, setSearch] = useState(null)
    const [tableState, setTableState] = useState(null)
    const {data, isFetching, fetchNextPage, hasNextPage} = useDraftFindings({search, sortBy: tableState?.sortBy})
    const draftFindings = getAllDataFromPages(data?.pages, "draftedFindings") || []
    const dispatch = useDispatch()
    const onTableChange = (tableState) => {
        setTableState(tableState)
    }

    const { ref, inView } = useInView()
    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView])

    const columns = [
        {
            Header: '#',
            accessor: 'index',
            className: 'w-14',
            disableSortBy: true
        },
        {
            Header: 'Customer',
            accessor: 'customer',
            Cell: props => {
                const [value, id] = props.value
                return (
                    <Link className={"link"} to={`${mainUrl}/dashboard/${id}/enterprise_dashboard`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            Header: 'Pentest',
            accessor: 'pentest',
            Cell: props => {
                const [value, id] = props.value
                return (
                    <Link className={"link"} to={`${mainUrl}/all_pentests/${id}/dashboard`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            Header: 'Asset',
            accessor: 'asset',
            Cell: props => {
                const [value, id] = props.value
                return (
                    <Link className={"link"} to={`${mainUrl}/all_assets/${id}/dashboard`}>
                        {value}
                    </Link>
                )
            }
        },
        {
            Header: 'Finding',
            accessor: 'finding'
        },
        {
            Header: '',
            accessor: 'actions',
            disableSortBy: true,
            Cell: props => {
                const item = props.value
                return (
                    <>
                        <Button onClick={() => {
                            dispatch(setAddFindingsModal({
                                open: true,
                                asset_id: item.pentest_asset_id,
                                finding_id: item.id,
                            }))
                        }} variant={"text"} className={"!text-headings"} rightArrow={true}>Complete Now</Button>
                    </>
                )
            },
            className: 'w-[170px]'
        }
    ]
    
    const tableData = draftFindings?.map((item, index) => ({
        index: get2DigitFormattedNumber(index + 1),
        customer: [item?.pentest?.company?.company_name, item?.pentest?.company?.id],
        pentest: [item?.pentest?.name, item?.pentest?.id],
        finding: item?.title || "-",
        asset: [item?.pentest_asset?.name, item?.pentest_asset?.id],
        actions: item
    })) || []

    return (
      <div className={"my-1"}>
        {(isFetching) && <MainLoader />}
           <div className={"flex-grow"}>
                    <Breadcrumbs data={BREADCRUMB_DATA} />
            </div>
                <AllTableData
                        title={"Draft Findings"}
                        searchText={"Search for any Findings, Keywords, etc."}
                        description={"See the list of all your draft findings below"}
                        columns={columns}
                        tableData={tableData}
                        dependencyArray={[draftFindings]}
                        onTableChange={onTableChange}
                        onSearch={value => setSearch(value)}
                        tableClassName={"lg:!max-h-[calc(100vh-300px)]"}
                        moreRef={ref}
                        hasNextPage={hasNextPage}
                        filterOptions = {[]}
                />
      </div>
    );
};

export default DraftFindings;
