import {createSlice} from '@reduxjs/toolkit'

export const commonSlice = createSlice({
    name: 'form',
    initialState: {
        errors: {
            customer: null,
            duplicate: null
        }
    },
    reducers: {
        setFormError: (state, action) => {
            return {
                ...state,
                errors: {...state.errors, ...action.payload}
            }
        },
        clearFormError: (state, action) => {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload]: null
                }
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setFormError, clearFormError
} = commonSlice.actions

export default commonSlice.reducer
