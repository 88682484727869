import {createSlice} from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        forgotPasswordModal: false,
        tokenFromOtp: null,
        formErrors: {
            login_form: null,
            otp_form: null,
            password_form: null
        },
        forgotPasswordData: {},
        user: {},
        token: null,
        openAuthenticatorModal: false,
        _2FaData: {},
        integrationsEnabled: {
            is_genpt_enabled: false,
        }
    },
    reducers: {
        setForgotPasswordModal: (state, action) => {
            return {...state, forgotPasswordModal: action.payload}
        },
        setTokenFromOtp: (state, action) => {
            return {...state, tokenFromOtp: action.payload}
        },
        setFormError: (state, action) => {
            return {
                ...state,
                formErrors: {...state.formErrors, ...action.payload}
            }
        },
        setForgotPasswordData: (state, action) => {
            return {
                ...state,
                forgotPasswordData: {...state.forgotPasswordData, ...action.payload}
            }
        },
        clearFormError: (state, action) => {
            return {
                ...state,
                formErrors: {
                    ...state.formErrors,
                    [action.payload]: null
                }
            }
        },
        setAuthInputs: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setIntegrationsEnabled: (state, action) => {
            return {
                ...state,
                integrationsEnabled: {...state.integrationsEnabled, ...action.payload}
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setForgotPasswordModal, setTokenFromOtp, setFormError, clearFormError, setForgotPasswordData, setAuthInputs, setIntegrationsEnabled
} = authSlice.actions

export default authSlice.reducer
