import React from 'react';
import styles from "../../Admin/Dashboard/dashboard.module.scss";
import Typography from "../../../../components/Typography";
import { PermissionProvider } from '../../../../hoc/withPermissions';
import { SIEMBA_ADMIN } from '../../../../utils/roles';

const VulnerabilityStatus = ({count, assetList}) => {
    const assetCount = assetList?.length;
    return (
        <div className={`${styles.overview} h-full`}>
            <div className={styles.background}></div>
            <div className={"flex"}>
                <div className={"flex-1"}>
                    <Typography variant={"h5"}>You have 
                    <span className={"text-secondary-900 font-bold"}> {count || 0} open confirmed findings </span>
                    in <span className={"text-secondary-900 font-bold"}> {assetCount || 0} { assetCount === 1 ? 'Asset' : 'Assets' }</span>
                    </Typography>
                </div>
                <div>
                </div>
            </div>
        </div>
    );
};

export default VulnerabilityStatus;
