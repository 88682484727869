import React from 'react';
import ReactTable from '../../../../../../components/Table';
import Typography from '../../../../../../components/Typography';
import { Parser } from "html-to-react";
import { CertificateIssuerColors, SSLTLSColors } from '../../../../../../utils/constants/asset';
import CounterChartWidget from '../../../../../../components/Widgets/CounterChartWidget';
import moment from 'moment';

const Insight5 = (props) => {
    const { data } = props;
    const htmlParser = new Parser();

    const columns = [
        {
            Header: 'Domain',
            accessor: 'domain',
        },
        {
            Header: 'Expiry Date',
            accessor: 'expiryDate',
            disableSortBy: true,
            Cell: props => {
                const expiryDate = props?.value;
                return (  
                    moment(expiryDate).format('MM/DD/YYYY')
                )
            }
        },
        {
            Header: 'Registrar',
            accessor: 'registrar',
            disableSortBy: true,
            Cell: props => {
                const registrar = props?.value;
                return (  
                    <span className="text-camel-case">{registrar}</span>
                )
            }
        },
    ];

    const tableData = data?.data?.map((item, index) => {
        return ({
            domain: item?.domain,
            expiryDate: item?.expiryDate,
            registrar: item?.registrar,
        })
    });

    const graphData = [];
    data?.graphData?.map((graph) => {
        graphData.push({
            name: graph?.name?.toUpperCase(),
            displayName: graph?.name?.toUpperCase(),
            value: graph?.count
        });
    });

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='pentest mr-5'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Assets</Typography>
                    </div>
                    {
                        data?.data &&
                            <ReactTable
                                columns={columns}
                                data={tableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight5;