import React, {memo, useMemo} from 'react';
import Storage from "../utils/Storage";
import {Navigate, useLocation} from 'react-router-dom'
import MainLoader from "../components/Loader/MainLoader";
import PropTypes from "prop-types";
import {getAccessDetails} from "../actions/commonActions";
import {useValidateLogin} from "../hooks/auth";
import {useCustomerDetails} from "../hooks/customer";
import {SIEMBA_CUSTOMER_STAGES_ROUTES} from "../utils/constants";
import {SIEMBA_CUSTOMER} from "../utils/roles";

const withPermissions = (WrappedComponent, options = {}) => {
    const {roles = []} = options
    return (props) => {
        const {isLoading, data} = useValidateLogin()
        const {data: customer, isLoading: isCustomerLoading} = useCustomerDetails(data?.user?.user_type === 'customer')
        let {pathname} = useLocation()
        // find user has access to routes
        let {
            hasAccess,
            mainRoutes,
            ignoredRoutes
        } = useMemo(() => getAccessDetails(roles, data?.user), [roles, data?.user])
        let hasAccessToRoutes = mainRoutes.some(el => pathname.startsWith(el))
        if (data?.success && !hasAccessToRoutes && !ignoredRoutes.includes(pathname)) {
            let splitPath = pathname?.split("/")
            splitPath[1] = mainRoutes[0]?.substring(1)
            let redirect_url = splitPath?.join("/")
            return (<Navigate replace to={redirect_url}/>)
        }
        let profileCompleted = false;
        // find customer current stage to redirect
        if (data?.user?.role !== SIEMBA_CUSTOMER) {
            profileCompleted = true
        } else if (["/connect_siemba"].includes(pathname)) {
            profileCompleted = true
        } else if (customer?.success && customer?.customerProfileStage !== "dashboard") {
            // if (Storage.getData('customerProfileStage', true) === customer.customerProfileStage)
            //     Storage.removeData('customerProfileStage', true)
            // const sessionSavedRoute = Storage.getData('customerProfileStage', true)
            // let stageRoute = SIEMBA_CUSTOMER_STAGES_ROUTES[sessionSavedRoute || customer.customerProfileStage]
            // if (pathname !== stageRoute)
            //     return (<Navigate replace to={stageRoute}/>)
            profileCompleted = true
        } else if (customer?.customerProfileStage === "dashboard") {
            // let dashboardRoute = SIEMBA_CUSTOMER_STAGES_ROUTES[customer.customerProfileStage]
            // if (pathname.startsWith("/profile") || pathname.startsWith("/pentest"))
            //     return (<Navigate replace to={dashboardRoute}/>)
            profileCompleted = true
        }

        return (
            <div>
                {
                    !Storage.token ? <Navigate to={`/?redirect_uri=${window.location.href}`}/> :
                        (
                            (isLoading || isCustomerLoading) ? <MainLoader/> :
                                (
                                    <>
                                        {data.success && hasAccess && profileCompleted &&
                                            <WrappedComponent {...props}/>}
                                    </>
                                )
                        )
                }
            </div>
        );
    }

};


const PermissionProvider = memo((props) => {
    const {children, roles = []} = props
    const {data} = useValidateLogin()
    let {hasAccess} = useMemo(() => getAccessDetails(roles, data?.user), [roles, data?.user])

    return (
        <>
            {
                data?.success && hasAccess && children
            }
        </>
    );
});

PermissionProvider.propTypes = {
    roles: PropTypes.array
}

export {withPermissions as default, PermissionProvider}
