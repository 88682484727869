import {createSlice} from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'admin.customer',
    initialState: {
        addCustomerModal: false,
    },
    reducers: {
        setAddCustomerModal: (state, action) => {
            return {
                ...state,
                addCustomerModal: action.payload
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAddCustomerModal,
} = authSlice.actions

export default authSlice.reducer
