import React from 'react';

const MyTeamIcon = () => {
    return (
        <svg width="24" height="24" className={"inline-block"} viewBox="0 0 24 24" fill="currentColor"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8081 17.173L14.1216 16.6776C14.0719 16.6033 14.0224 16.529 13.9728 16.4546C14.0719 16.3556 14.1465 16.2317 14.2456 16.1078C14.4688 15.7857 14.6673 15.4389 14.7913 15.117C14.841 14.9931 14.8904 14.8692 14.9401 14.7455C15.1386 14.572 15.2377 14.3491 15.2377 14.1015V13.5565C15.2377 13.383 15.188 13.2344 15.0889 13.0858V12.3921C15.0889 10.98 13.8983 9.84058 12.4348 9.84058H11.6409C10.1775 9.84058 8.9868 10.9802 8.9868 12.3921V13.0858C8.88766 13.2344 8.83801 13.383 8.83801 13.5565V14.1015C8.83801 14.3493 8.93715 14.5969 9.1356 14.7455C9.18526 14.8694 9.23475 14.9933 9.2844 15.117C9.40846 15.4638 9.60691 15.8107 9.83009 16.1078C9.90449 16.2317 10.0038 16.3308 10.1029 16.4546C10.0285 16.529 9.97889 16.5785 9.95415 16.6776L8.26755 17.173C7.34984 17.4208 6.72974 19.7245 6.35759 21.6072L6.30811 21.7311L6.40725 21.7807C7.87065 22.6478 9.58218 23.1679 11.3185 23.2672C11.5666 23.2919 11.7898 23.2919 12.0377 23.2919C13.9971 23.2919 15.932 22.7716 17.6186 21.7807L17.7178 21.7312L17.693 21.6321C17.1477 18.8577 16.5276 17.3713 15.8081 17.1731L15.8081 17.173Z"
                fill="white"/>
            <path
                d="M20.2727 14.7206L20.2479 14.6463C20.2232 14.5473 20.1488 14.4728 20.0495 14.3985L19.9007 14.2995L20.0247 14.1756C20.1488 14.0517 20.2728 13.9031 20.372 13.7545C20.5952 13.4572 20.7689 13.1352 20.8929 12.8131C20.9673 12.6645 21.017 12.5406 21.0417 12.4167L21.0665 12.3671L21.0912 12.3424C21.2153 12.2434 21.3144 12.0699 21.3144 11.8966V11.3516C21.3144 11.2277 21.2648 11.1038 21.1904 11.0048L21.1656 10.9552V10.1625C21.1656 8.92391 20.1238 7.93311 18.8589 7.93311H18.0651C16.8001 7.93311 15.7584 8.92391 15.7584 10.1625V10.9553L15.7336 11.0049C15.6592 11.1039 15.6345 11.2278 15.6345 11.3517V11.8967C15.6345 12.0702 15.7089 12.2188 15.8577 12.3425L15.8824 12.3673L15.9072 12.4168C15.9568 12.5407 16.0063 12.6894 16.056 12.8132C16.18 13.1353 16.3536 13.4573 16.577 13.7546C16.6761 13.9032 16.8002 14.0519 16.9242 14.1757L17.0483 14.2996L16.8995 14.3987C16.8003 14.473 16.7258 14.5473 16.701 14.6465L16.6763 14.7208L15.4854 15.0427L15.4606 15.0674C15.4359 15.1664 15.3862 15.2656 15.3366 15.3646C15.2125 15.6618 15.0637 15.9592 14.8652 16.2564L15.9567 16.5785C16.5271 16.7271 17.2713 17.2721 17.9658 20.0466C18.0402 20.3686 18.1146 20.6906 18.189 21.0127C20.1733 19.6006 21.5871 17.5693 22.1577 15.2903C22.133 15.2903 22.1081 15.2655 22.1081 15.2655L20.2727 14.7206Z"
                fill="white"/>
            <path
                d="M8.04413 16.5787L9.13556 16.2566C8.9371 15.9593 8.78831 15.662 8.66423 15.3647C8.61458 15.2657 8.58983 15.1665 8.54018 15.0922L8.51544 15.0675L7.39929 14.7454L7.37454 14.6711C7.3498 14.5968 7.2754 14.4976 7.17609 14.4233L7.02729 14.3243L7.15135 14.2004C7.2754 14.0765 7.39946 13.9279 7.4986 13.7793C7.7218 13.4821 7.89551 13.16 8.01958 12.8379C8.09398 12.6893 8.14363 12.5654 8.16837 12.4415L8.19312 12.3919L8.21786 12.3672C8.34191 12.2682 8.44106 12.0947 8.44106 11.9214V11.3764C8.44106 11.2525 8.3914 11.1286 8.34192 11.0296L8.29226 10.9555V10.1627C8.29226 8.92404 7.25048 7.93323 5.98555 7.93323H5.19172C3.92675 7.93323 2.88501 8.92404 2.88501 10.1627V10.9555L2.83535 11.0049C2.76095 11.1039 2.73621 11.2278 2.73621 11.3517V11.8967C2.73621 12.0702 2.81061 12.2188 2.95941 12.3425L2.98415 12.3672L3.00889 12.4168C3.05855 12.5407 3.10804 12.6646 3.15769 12.8132C3.28175 13.1353 3.45529 13.4572 3.67867 13.7546C3.77781 13.9032 3.90187 14.0518 4.02592 14.1757L4.14998 14.2996L4.00118 14.3986C3.90204 14.4729 3.82747 14.5472 3.80272 14.6464L3.77798 14.7207L1.91766 15.2657C1.89292 15.2657 1.868 15.2904 1.84326 15.2904C2.24017 16.8511 3.03382 18.3127 4.14997 19.5266C4.64601 20.0716 5.21647 20.5917 5.81186 21.013C5.88626 20.6415 5.96066 20.3194 6.05997 19.9973C6.72956 17.2725 7.47373 16.7275 8.04418 16.5787L8.04413 16.5787Z"
                fill="white"/>
            <path
                d="M22.0339 6.19899L21.4882 5.50537C20.7193 4.53924 19.8016 3.72175 18.7597 3.02813C16.9243 1.81424 14.816 1.14534 12.509 1.02143C10.8968 0.922418 9.30934 1.17004 7.92022 1.69033C5.29101 2.68114 3.90197 3.91976 3.00898 4.8612C2.93458 4.9355 2.488 5.43092 2.2648 5.72832L2.24006 5.75303C1.74402 6.37234 1.34711 7.04123 1.02474 7.68527C1 7.73487 1 7.75958 1 7.80917C1 7.90818 1.04966 7.98266 1.1488 8.03208C1.24794 8.08167 1.42165 8.08167 1.49605 7.90818C1.69451 7.48708 1.94244 7.06597 2.21528 6.64484L2.53779 6.14943H2.58728C4.7701 3.2262 8.21784 1.517 11.9633 1.517C12.6825 1.517 13.4267 1.5913 14.1461 1.7152C16.6762 2.18574 19.0077 3.47395 20.6696 5.35656L21.6122 6.397L20.4217 6.96672L22.8277 8.52743L23.0261 5.7281L22.0339 6.19899Z"
                fill="white"/>
        </svg>
    );
};

export default MyTeamIcon;
