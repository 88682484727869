import React from 'react';
import ReactTable from '../../../../../../components/Table';
import Typography from '../../../../../../components/Typography';
import { Parser } from "html-to-react";
import CounterChartWidget from '../../../../../../components/Widgets/CounterChartWidget';
import { colorSelector } from '../../../../../../utils/constants/asset';

const Insight2 = (props) => {
    const { data } = props;
    const htmlParser = new Parser();

    const columns = [
        {
            Header: 'Asset',
            accessor: 'asset',
            className: 'w-[450px]',
        },
        {
            Header: 'Open Ports Count',
            accessor: 'openPortsCount',
            disableSortBy: true,
        },
        {
            Header: 'Open Ports',
            accessor: 'openPorts',
            disableSortBy: true,
            Cell: props => {
                const portsList = props?.value;
                return (  
                    portsList.map((port, index) => (
                        `${port}${portsList.length > (index + 1) ? ', ' : ''}`
                    ))
                )
            }
        },
    ];

    const tableData = data?.data?.map((item, index) => {
        return ({
            asset: item?.assetName,
            openPortsCount: item?.openPortsCount,
            openPorts:  item?.openPorts
        })
    });

    const graphData = [];
    const Insight2Color = {};
    data?.graphData?.map((graph, index) => {
        graphData.push({
            name: graph?.name?.toUpperCase(),
            displayName: `Port ${graph?.name?.toUpperCase()}`,
            value: graph?.count
        });
        Insight2Color[graph?.name?.toLowerCase()] = {
            name: graph?.name?.toUpperCase(),
            color: colorSelector[index]
        }
    });

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph stackedbar-container'>
                    {
                        data?.graphData &&
                            <CounterChartWidget
                                className={"!my-0 p-0 !pt-0 !pb-0 h-auto"}
                                chartClassName={"w-[265px]"}
                                data={graphData || []} title={""}
                                colors={Insight2Color}
                            />
                    }
                </div>
            </div>
            <div className='lower-section'>
                <div className='pentest mr-5'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Assets</Typography>
                    </div>
                    {
                        data?.data &&
                            <ReactTable
                                columns={columns}
                                data={tableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight2;