import axios from "axios";
import {uploadFile} from "./misc";

export const createCustomer = async (data) => {
    let dataWithLogoKey = data?.customers?.map(async el => {
        const logo = el?.company_logo?.[0];
        if (logo) {
            el.logo = await uploadFile({
                file: logo,
                category: "logo"
            })
        }
        delete el.company_logo;
        return el;
    })
    let customers = await Promise.all(dataWithLogoKey)
    let res = await axios.post('/customer/create', {customers})
    return res.data;
}

export const updateCustomerDetails = async (data) => {
    let {id, ...payload} = data
    if (payload.profile_picture?.[0]) {
        payload.profile_picture = await uploadFile({
            file: payload.profile_picture?.[0],
            category: "profile_picture"
        })
    } else
        delete payload.profile_picture
    let res = await axios.put(`customer/update/${id}`, payload)
    return res.data;
}
