export const severityColors = {
    critical: {
        name: "Critical",
        color: "var(--functional-red-900)",
    },
    high: {
        name: "High",
        color: "var(--functional-red-700)",
    },
    medium: {
        name: "Medium",
        color: "var(--functional-orange)",
    },
    low: {
        name: "Low",
        color: "var(--functional-yellow)",
    },
    informational: {
        name: "Informational",
        color: "var(--functional-blue-50)",
    }
}

export const getItemType = (item) => {
    try {
        const itemName = item?.name;
        let itemType = "";
        if (itemName) {
            itemType = itemName.replace(" ", "_").toLowerCase();
        }
        return itemType;
    } catch (e) {
        console.log("Inavlid Item Type");
    }
}

export const filterChartData = (data) => {
    try {
        const chartData = data?.filter(item => item.value !== 0);
        return chartData;
    } catch (e) {
        console.log("Error parsing chartdata")
    }
}