import { useQuery } from "react-query";
import { CTEM_ASSET_LIST, CTEM_INSIGHTS_DETAILS } from "./utils/constants";
import axios from "axios";

export const useCTEMInsightDetails = (companyId, insightId) => {
    return useQuery([CTEM_INSIGHTS_DETAILS, companyId, insightId], async () => {
        let res = await axios.get(`/ctem/insight-details?companyId=${companyId}&insightId=${insightId}`)
        return res.data;
    })
}

export const useCTEMAssetList = (companyId) => {
    return useQuery([CTEM_ASSET_LIST, companyId], async () => {
        let res = await axios.get(`/ctem/ctem-asset-list?companyId=${companyId}`)
        return res.data;
    })
}

export const useCTEMPrioritizedFindingList = (companyId) => {
    return useQuery([CTEM_ASSET_LIST, companyId], async () => {
        let res = await axios.get(`/ctem/prioritized-finding?companyId=${companyId}`)
        return res.data;
    })
}