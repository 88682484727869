import React from 'react';
import {
    Account,
    AddCustomer,
    AddFindings, AddFindingsToThisAsset,
    Logout,
    MyTeam,
    SeeScope, SlackButton,
    StartNewPentest,
    UpdatePentestLandScapeButton
} from "./Menu";
import {Divider} from "@mui/material";
import {PermissionProvider} from "../../hoc/withPermissions";
import {SIEMBA_ADMIN, SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER, SIEMBA_TESTER} from "../../utils/roles";
import {SLACK_CLIENT_ID} from "../../utils/constants";
import {useValidateLogin} from "../../hooks/auth";
import {useCustomerDetails} from "../../hooks/customer";

const ExpandedMenu = () => {
    const {data} = useValidateLogin()
    const {data: customer, isFetching} = useCustomerDetails(data?.user?.user_type === 'customer')

    return (
        <div>
            <div className={"flex flex-row m-1"}>
                <div className={"m-2 flex-1"}>
                    <Account/>
                </div>
                <Divider orientation={"vertical"} flexItem/>
                <div className={"m-2 flex-1"}>
                    <Logout/>
                </div>
            </div>
            <PermissionProvider roles={[SIEMBA_ADMIN]}>
                <AddCustomer className={"my-1"}/>
                <MyTeam className={"my-1"}/>
            </PermissionProvider>
            <PermissionProvider roles={[SIEMBA_TESTER]}>
                <AddFindingsToThisAsset className={"my-1"}/>
            </PermissionProvider>
            <PermissionProvider roles={[SIEMBA_TESTER]}>
                <AddFindings className={"my-1"}/>
            </PermissionProvider>
            <PermissionProvider roles={[SIEMBA_CUSTOMER_ENGINEER, SIEMBA_CUSTOMER]}>
               <SeeScope className={"my-1"}/>
            </PermissionProvider>
            <PermissionProvider roles={[SIEMBA_CUSTOMER]}>
                <UpdatePentestLandScapeButton className={"my-1"}/>
                {(SLACK_CLIENT_ID && !isFetching && !customer?.slackConnectionId) && <SlackButton className={"my-1"}/>}
            </PermissionProvider>
            <PermissionProvider roles={[SIEMBA_CUSTOMER]}>
                <StartNewPentest className={"my-1"}/>
            </PermissionProvider>
        </div>
    );
};

export default ExpandedMenu;
