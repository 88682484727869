import React, { useContext } from 'react';
import { Parser } from "html-to-react";
import ReactTable from '../../../../../../components/Table';
import Typography from '../../../../../../components/Typography';
import CounterChartWidget from "../../../../../../components/Widgets/CounterChartWidget";
import { Link } from 'react-router-dom';
import { LayoutContext } from '../../../../../../Providers/LayoutProvider';
import { pentestFindingSeverityColors } from '../../../../../../utils/constants/asset';

const Insight4 = (props) => {
    const { data } = props;
    const htmlParser = new Parser();
    const { mainUrl } = useContext(LayoutContext);

    const pentestColumns = [
        {
            Header: 'Title',
            accessor: 'title',
            disableSortBy: false,
            Cell: props => {
                const [findingTitle, findingId] = props?.value;
                return (
                    <Link className={"link"} target={"_blank"} to={`${mainUrl}/pentests/all_findings/${findingId}/dashboard`}>
                        {findingTitle}
                    </Link>
                )
            }
        },
        {
            Header: 'Asset',
            accessor: 'asset',
        },
        {
            Header: 'Severity',
            accessor: 'severity',
            disableSortBy: false,
            Cell: props => {
                const severity = props?.value;
                return (
                    <span className='text-camel-case'>{severity}</span>
                )
            }
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableSortBy: false,
            Cell: props => {
                const status = props?.value;
                return (  
                    status === 're-opened' ?
                        <span className='text-camel-case'>
                            Re-Opened
                        </span>
                    :
                        <span className='text-camel-case'>
                            {status.replace("_", " ")}
                        </span>
                    
                )
            }
        }
    ];

    const pentestTableData = data?.data?.map((item, index) => {
        return ({
            title: [item?.findingTitle, item?.findingId],
            asset: item?.assetName,
            severity: item?.severity,
            status: item?.status
        })
    });

    const graphData = data?.graphData.map((data) => {
        return {
            name: data?.severity,
            value: Number(data?.count),
        }
    });

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph'>
                    <CounterChartWidget
                        className={"!my-0 p-0 !pt-0 !pb-0 h-auto"}
                        data={graphData || []} title={""}
                        colors={pentestFindingSeverityColors} 
                    />
                </div>
            </div>
            <div className='lower-section'>
                <div className='pentest mr-5'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Pentests</Typography>
                    </div>
                    {
                        data?.data &&
                            <ReactTable
                                columns={pentestColumns}
                                data={pentestTableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight4;