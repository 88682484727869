import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
import NotificationComponents from './NotificationComponents';
import { AvatarImage } from '../../Dashboard/Table/AvatarGroups';
import { getNotificationAction, getUserAvatarDetails, getNotificationTime } from './notification-helper';
import UnReadIcon from '../../SvgIcons/UnReadIcon';
import { useNavigate } from 'react-router-dom';
import { setAddBlockerModal, setReportModal } from '../../../redux/reducers/dashboard';
import { useDispatch } from 'react-redux';
import { useMarkNotificationAsRead } from '../../../hooks/notifications';

const Notification = ({ notification, onClose, mainUrl = "" }) => {

    let NotificationComponent = NotificationComponents["DEFAULT"];
    if (NotificationComponents[notification?.type]) {
        NotificationComponent = NotificationComponents[notification?.type]
    }

    const markNotification = useMarkNotificationAsRead();
    const userAvatarDetails = getUserAvatarDetails(notification);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        const action = getNotificationAction(notification, mainUrl);
        if (action?.type === "NAVIGATE") {
            navigate(action?.url);
        } else if (action?.type === "OPEN_MODAL" && action.modalType === "BLOCKER") {
            dispatch(setAddBlockerModal({
                open: true,
                id: action?.id,
                pentest_asset_id: action?.assetId
            }));
            if (action?.url) {
                navigate(action?.url);
            }            
        } else if (action?.type === "OPEN_MODAL" && action.modalType === "REPORT") {
            dispatch(
                setReportModal({
                    open: true,
                    pentest_asset_id: action?.assetId,
                    pentest_id: action?.pentestId,
                    report: action?.data
                })
            );
        }
        markNotification.mutateAsync(notification?.id)
        onClose();
    }

    const avatarImageSection = userAvatarDetails?.profile_picture === "APP_LOGO" ? (
        <Avatar src="/images/avatar.png" className="mt-2 !w-[59px] !h-[59px]" />
    ) : (     
        <AvatarImage user={userAvatarDetails} className="!bg-primary-800 mt-2 !w-[59px] !h-[59px]" />
    );

    const handleLinkDefaultAction = (event) => {
        event.stopPropagation();
        onClose();
    }

    const readSection = !notification?.seen_at ? ( 
        <>
            <div className="font-normal text-base text-primary-500">{getNotificationTime(notification?.created_at)}</div>
            <UnReadIcon className={"!text-white"} />
        </>
    ) : (
        <div className="font-normal text-base text-primary-500">{getNotificationTime(notification?.created_at)}</div>
    );

    return (
        <ListItem onClick={() => handleClick()}
            alignItems="flex-start"
            className="notification-item !items-center"
        >
            <ListItemAvatar>
                {avatarImageSection}
            </ListItemAvatar>
            <ListItemText
                primary=""
                secondary={
                    <React.Fragment>
                        <NotificationComponent
                            notification={notification}
                            mainUrl={mainUrl}
                            onLinkClick={handleLinkDefaultAction} />
                    </React.Fragment>
                }
                className="pl-4"
            />
            <ListItemSecondaryAction className="!right-0 cursor-pointer"  onClick={() => handleClick()}>
                <div className="w-[50px] text-center">{readSection}</div>
            </ListItemSecondaryAction>

        </ListItem>
    )
}

export default Notification;