import React, {useEffect} from 'react';
import ReactDOM from "react-dom";
import styles from './styles.module.scss'

let loader = document.createElement("div");
loader.id = "global-loader";
const body = document.getElementsByTagName("body");

const MainLoader = () => {
    useEffect(() => {
        body[0].appendChild(loader);
    }, [])
    return ReactDOM.createPortal(
        <div className={styles.loader} id={"loader"}>
            <svg width="400" height="400" viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3"
                      d="M400 200C400 310.457 310.457 400 200 400C89.5433 400 0.000244141 310.457 0.000244141 200C0.000244141 89.5433 89.5433 0.000244141 200 0.000244141C310.457 0.000244141 400 89.5433 400 200ZM38.7902 200C38.7902 289.034 110.966 361.21 200 361.21C289.034 361.21 361.21 289.034 361.21 200C361.21 110.966 289.034 38.7902 200 38.7902C110.966 38.7902 38.7902 110.966 38.7902 200Z"
                      fill="url(#paint0_radial_384_24291)"/>
                <path
                    d="M170.275 21.5949C168.538 11.1705 175.585 1.21465 186.128 0.481285C232.259 -2.72767 278.308 10.1507 316.256 37.2591C354.203 64.3674 381.317 103.753 393.238 148.432C395.963 158.643 388.83 168.537 378.405 170.274C367.981 172.011 358.233 164.919 355.277 154.773C345.179 120.1 323.694 89.6051 294.01 68.4002C264.326 47.1953 228.512 36.7578 192.438 38.4472C181.881 38.9416 172.012 32.0194 170.275 21.5949Z"
                    fill="#12AFB8"/>
                <defs>
                    <radialGradient id="paint0_radial_384_24291" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(15.0003 200) rotate(0.0416342) scale(416.284 2502.42)">
                        <stop offset="0.0864582" stopColor="var(--primary-500)"/>
                        <stop offset="0.492708" stopColor="var(--secondary-700)"/>
                        <stop offset="0.810417" stopColor="var(--tertiary-400)"/>
                    </radialGradient>
                </defs>
            </svg>
        </div>,
        loader,
    );
};

export default MainLoader;
