import React from 'react';

const ReportReadyIcon = () => {
    return (
        <svg className={`inline-block`} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.37099 20.9998H13.6839C12.9114 20.2242 12.4243 19.1085 12.4243 17.8707C12.4243 15.536 14.1536 13.6372 16.2797 13.6372C16.5267 13.6372 16.7669 13.6639 17 13.7136V4.50534C17 3.67625 16.3841 3 15.6291 3H5.37105C4.61245 3 4 3.67625 4 4.50534V19.4945C4 20.3236 4.61242 20.9998 5.37099 20.9998ZM6.2583 5.74703H14.7417C14.9331 5.74703 15.0897 5.91897 15.0897 6.12912C15.0897 6.34315 14.9331 6.5112 14.7417 6.5112H6.2583C6.06692 6.5112 5.91033 6.34315 5.91033 6.12912C5.91033 5.91897 6.06692 5.74703 6.2583 5.74703ZM6.2583 8.09685H14.7417C14.9331 8.09685 15.0897 8.26879 15.0897 8.47894C15.0897 8.68909 14.9331 8.86102 14.7417 8.86102H6.2583C6.06692 8.86102 5.91033 8.68909 5.91033 8.47894C5.91033 8.26879 6.06692 8.09685 6.2583 8.09685ZM6.2583 10.4429H14.7417C14.9331 10.4429 15.0897 10.6149 15.0897 10.825C15.0897 11.039 14.9331 11.2071 14.7417 11.2071H6.2583C6.06692 11.2071 5.91033 11.039 5.91033 10.825C5.91033 10.6149 6.06692 10.4429 6.2583 10.4429ZM6.2583 12.7927H14.7417C14.9331 12.7927 15.0897 12.9647 15.0897 13.1748C15.0897 13.385 14.9331 13.5569 14.7417 13.5569H6.2583C6.06692 13.5569 5.91033 13.385 5.91033 13.1748C5.91033 12.9647 6.06692 12.7927 6.2583 12.7927ZM6.2583 15.1426H10.7714C10.9628 15.1426 11.1194 15.3106 11.1194 15.5247C11.1194 15.7348 10.9628 15.9067 10.7714 15.9067H6.2583C6.06692 15.9067 5.91033 15.7348 5.91033 15.5247C5.91033 15.3106 6.06692 15.1426 6.2583 15.1426ZM6.2583 17.4885H10.7714C10.9628 17.4885 11.1194 17.6604 11.1194 17.8706C11.1194 18.0807 10.9628 18.2527 10.7714 18.2527H6.2583C6.06692 18.2527 5.91033 18.0807 5.91033 17.8706C5.91033 17.6604 6.06692 17.4885 6.2583 17.4885Z"
                fill="#12AFB8"/>
            <path
                d="M20.7129 17.8564C20.7129 15.7329 18.9843 14 16.8564 14C14.7285 14 13 15.7329 13 17.8564C13 19.9843 14.7285 21.7129 16.8564 21.7129C18.9842 21.7129 20.7129 19.9843 20.7129 17.8564ZM18.7081 17.0835L16.5633 19.2326C16.4827 19.3133 16.3722 19.3557 16.2617 19.3557C16.1471 19.3557 16.0409 19.3133 15.9601 19.2326L15.0045 18.2727C14.8389 18.1071 14.8389 17.8395 15.0045 17.6738C15.17 17.5082 15.4376 17.5082 15.6034 17.6738L16.2617 18.3321L18.1092 16.4846C18.2748 16.319 18.5424 16.319 18.7081 16.4846C18.8739 16.6503 18.8739 16.9177 18.7081 17.0835H18.7081Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default ReportReadyIcon;
