import React from 'react';
import styles from "../../Admin/Dashboard/dashboard.module.scss";
import Typography from "../../../../components/Typography";
import { get2DigitFormattedNumber, getAllDataFromPages } from "../../../../actions/commonActions";
import { useListVulnerabilityAssessment, useWelcomeBannerUserManagement } from '../../../../hooks/dashboard';
import pluralize from "pluralize";
import { PermissionProvider } from '../../../../hoc/withPermissions';
import { SIEMBA_ADMIN } from '../../../../utils/roles';

const Status = ({count, companyCount}) => {
    return (
        <div className={`${styles.overview} h-full`}>
            <div className={styles.background}></div>
            <div className={"flex"}>
                <div className={"flex-1"}>
                    <Typography variant={"h5"}>You have
                    <span className={"text-secondary-900 font-bold"}> {count || 0} assets with Vulnerability Assessments </span>
                    <PermissionProvider roles={[SIEMBA_ADMIN]}>
                    {
                        <>
                            from 
                            <span className={"text-secondary-900 font-bold"}> {companyCount || 0} { companyCount === 1 ? 'Customer' : 'Customers' }</span>
                        </>
                    }
                    </PermissionProvider>
                    </Typography>
                </div>
                <div>
                </div>
            </div>
        </div>
    );
};

export default Status;
