import React from 'react';
import PropTypes from 'prop-types';

const RecommissionIcon = ({className}) => {
    return (
        <svg width="28" height="28" viewBox="-4 -6 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={`inline-block ${className}`}>
            <path d="M0.720097 11.5199L13.6321 11.5199L18.9601 6.43193C19.1521 6.23991 19.248 6.00001 19.248 5.75993C19.248 5.51984 19.1521 5.25584 18.9601 5.06401L13.6321 1.38424e-05L0.720096 1.49712e-05C0.312118 1.50069e-05 5.43333e-05 0.312068 5.4369e-05 0.720059L0.000223097 10.8001C0.000223133 11.208 0.336218 11.5199 0.720097 11.5199ZM0.960166 0.959929L13.2482 0.959928L18.2882 5.75993L13.2482 10.5599L0.960167 10.5599L0.960166 0.959929Z" fill="#E34F0B"/>
            <g clip-path="url(#clip0_1985_34617)">
            <path d="M5.74994 7.7375L4.01244 6L3.42078 6.5875L5.74994 8.91666L10.7499 3.91666L10.1624 3.32916L5.74994 7.7375Z" fill="#E34F0B"/>
            </g>
            <defs>
            <clipPath id="clip0_1985_34617">
            <rect width="10" height="10" fill="white" transform="translate(2 1)"/>
            </clipPath>
            </defs>
        </svg>
    );
};

RecommissionIcon.propTypes = {
    className: PropTypes.string
};

export default RecommissionIcon
