export const SIEMBA_CUSTOMER = "customer_leader"
export const SIEMBA_CUSTOMER_ENGINEER = "customer_engineer"
export const SIEMBA_ADMIN = "siemba_admin"
export const SIEMBA_TESTER = "siemba_tester"


export const siembaRoles = {
    [SIEMBA_ADMIN]: {
        role: SIEMBA_ADMIN,
        title: "Siemba Admin",
        disabled: false,
        description: [],
        priority: 1
    },
    [SIEMBA_TESTER]: {
        role: SIEMBA_TESTER,
        title: "Pentester",
        disabled: false,
        description: [],
        priority: 2
    }
};