import React from 'react';
import ReactTable from '../../../../../../components/Table';
import Typography from '../../../../../../components/Typography';
import { Parser } from "html-to-react";
import BarChartComponent from '../../../../../../components/Widgets/barChart';
import { colorSelector } from '../../../../../../utils/constants/asset';

const Insight7 = (props) => {
    const { data } = props;
    const htmlParser = new Parser();

    const columns = [
        {
            Header: 'Asset',
            accessor: 'asset',
            className: 'w-[450px]',
        },
        {
            Header: 'Protocol',
            accessor: 'protocol',
            disableSortBy: true,
            Cell: props => {
                const protocol = props?.value;
                return (  
                    <span className="text-camel-case">{protocol.toUpperCase()}</span>
                )
            }
        },
        {
            Header: 'Port',
            accessor: 'port',
            disableSortBy: true,
        },
    ];

    const tableData = data?.data?.map((item, index) => {
        return ({
            asset: item?.assetName,
            protocol: item?.protocol,
            port: item?.port
        })
    });

    const graphData = [];
    const Insight7Color = {};
    data?.graphData?.map((graph, index) => {
        graphData.push({
            name: graph?.name?.toUpperCase(),
            value: graph?.count
        });
        Insight7Color[graph?.name?.toUpperCase()] = {
            name: graph?.name?.toUpperCase(),
            color: colorSelector[index]
        }
    });
    console.log('== Insight7Color', Insight7Color);

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant={"h7"} className={"text-headings"}>Observation</Typography>
                    <div>
                        {htmlParser.parse(data?.observation)}
                    </div>
                    <Typography variant={"h7"} className={"text-headings mt-5"}>Recommended Action</Typography>
                    <div>
                        {htmlParser.parse(data?.recommended_action)}
                    </div>
                </div>
                <div className='graph stackedbar-container'>
                    {
                        data?.graphData &&
                            <BarChartComponent 
                                className={"flex flex-row h-[250px] insight2-bar-chart mt-5"}
                                labelHeight="h-[50px]"
                                graphData={graphData || []} 
                                colors={Insight7Color}
                            />
                    }
                </div>
            </div>
            <div className='lower-section'>
                <div className='pentest mr-5'>
                    <div className='xl:col-span-12 heading mb-5'>
                        <Typography variant={"h7"} className={"text-headings"}>Assets</Typography>
                    </div>
                    {
                        data?.data &&
                            <ReactTable
                                columns={columns}
                                data={tableData || []}
                                className={"table lg:table-fixed middle-aligned"}
                                headerClassName={"background rounded sticky z-[5] top-0"}
                                bodyClassName={"text-headings"}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

export default Insight7;