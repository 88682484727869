import React from 'react';
import PropTypes from 'prop-types';

const LogoutIcon = props => {
    const {
        width = 21,
        height = 16,
        className
    } = props
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} className={`inline-block ${className}`}
             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2C0 0.89546 0.895457 0 1.99999 0H11C12.1046 0 13 0.89546 13 2V4C13 4.55226 12.5522 5 12 5C11.4477 5 11 4.55226 11 4V2H1.99999V14H11V12C11 11.4477 11.4477 11 12 11C12.5522 11 13 11.4477 13 12V14C13 15.1046 12.1046 16 11 16H1.99999C0.895457 16 0 15.1046 0 14V2ZM15.2928 4.2928C15.6833 3.90226 16.3164 3.90226 16.707 4.2928L19.707 7.2928C20.0974 7.68334 20.0974 8.31648 19.707 8.70702L16.707 11.707C16.3164 12.0975 15.6833 12.0975 15.2928 11.707C14.9023 11.3165 14.9023 10.6833 15.2928 10.2928L16.5856 8.9999H6.99988C6.44762 8.9999 5.99988 8.55216 5.99988 7.9999C5.99988 7.44764 6.44762 6.9999 6.99988 6.9999H16.5856L15.2928 5.707C14.9023 5.31646 14.9023 4.68332 15.2928 4.29278V4.2928Z"/>
        </svg>
    );
};

LogoutIcon.propTypes = {
    className: PropTypes.string
};

export default LogoutIcon
