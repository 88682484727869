import React, {useState} from "react";
import PropTypes from 'prop-types';
import TextField from "@mui/material/TextField";
import './mui_overrides.scss'
import MenuItem from "@mui/material/MenuItem";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMomentFns from '@mui/lab/AdapterMoment'

import CheckBox from "@mui/material/Checkbox";
import {Select} from "@mui/material";

export const MuiTextField = (props) => {
    return (
        <TextField
            margin={"normal"}
            fullWidth={true}
            {...props}
        />
    )
}

MuiTextField.propTypes = {
    label: PropTypes.node,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
};

export const MuiSelectOption = ({children, ...props}) => {
    return (
        <MenuItem className={"option"} {...props}>{children}</MenuItem>
    )
}

export const MuiDatePicker = (props) => {
    const [open, setOpen] = useState(false)
    const {error, helperText, disabled, textFieldProps} = props
    return (
        <LocalizationProvider dateAdapter={AdapterMomentFns}>
            <DatePicker
                open={open}
                PopperProps={{
                    placement: "bottom-end"
                }}
                className={"datepicker"}
                value={null}
                inputFormat={"MM/DD/YYYY"}
                {...props}
                onClose={() => setOpen(false)}
                renderInput={
                    (params) => {
                        return (<MuiTextField
                            {...params}
                            {...textFieldProps}
                            error={error}
                            helperText={helperText}
                            onClick={() => !disabled && setOpen(true)}
                            autoComplete={"off"}
                            InputProps={{
                                endAdornment: null
                            }}
                            inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                            }}
                        />)
                    }
                }
            />
        </LocalizationProvider>
    )
}

export const MuiCheckBox = ({className, ...props}) => {
    return (
        <CheckBox className={`checkbox ${className}`} {...props}/>
    )
}

MuiCheckBox.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool
};

export const MuiSelect = ({children, ...props}) => {
    return (
        <Select
            fullWidth={true}
            {...props}
        >
            {children}
        </Select>
    )
}
