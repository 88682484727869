import React from 'react';

const RetestReadyIcon = () => {
    return (
        <svg className={`inline-block`} width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.472 2.73602C15.2881 2.95205 16.056 3.26395 16.776 3.69602C17.496 4.12809 18.1681 4.63205 18.7681 5.23205C19.3681 5.83205 19.872 6.50412 20.3041 7.22412C20.712 7.94412 21.0481 8.71205 21.2641 9.52809C21.4801 10.3441 21.6001 11.1841 21.6001 12.0241C21.6001 12.4081 21.5762 12.8162 21.5281 13.2002C21.5281 13.2481 21.5041 13.2962 21.5041 13.3441C21.4562 13.7521 21.3601 14.1362 21.2641 14.4962C21.0481 15.3122 20.712 16.0802 20.3041 16.8002C19.872 17.5202 19.3681 18.1922 18.7681 18.7922L16.5601 16.5843C17.4722 15.6722 18.0722 14.5203 18.3362 13.2722C18.5762 12.0243 18.4562 10.7043 17.9762 9.52823C17.4962 8.35216 16.6562 7.34426 15.6001 6.62426C14.5441 5.90426 13.2722 5.54426 12.0001 5.54426L12 2.40002C12.384 2.40002 12.7679 2.424 13.176 2.47208C13.224 2.47208 13.296 2.49605 13.344 2.49605C13.7281 2.544 14.112 2.64003 14.472 2.73605L14.472 2.73602Z"
                fill="#12AFB8"/>
            <path
                d="M7.41692 16.584L5.20898 18.7919C5.80898 19.3919 6.45692 19.8959 7.20105 20.328L8.76105 17.6159C8.25695 17.328 7.80092 16.9921 7.41695 16.584L7.41692 16.584Z"
                fill="#12AFB8"/>
            <path
                d="M11.1365 18.432C10.8486 18.384 10.5605 18.336 10.2965 18.264L9.48047 21.288C9.8884 21.384 10.2965 21.4801 10.7044 21.528C11.1365 21.5759 11.5444 21.6001 11.9524 21.624V18.504C11.7125 18.4799 11.4245 18.456 11.1365 18.432L11.1365 18.432Z"
                fill="#12AFB8"/>
            <path
                d="M13.6562 18.264C14.2083 18.12 14.7362 17.904 15.2162 17.6161L16.7762 20.3282C16.0562 20.7361 15.2883 21.0721 14.4723 21.2882L13.6562 18.264Z"
                fill="#12AFB8"/>
            <path
                d="M5.73689 13.6799C5.88086 14.232 6.09689 14.7599 6.38482 15.2399L3.67289 16.7999C3.26496 16.0799 2.92892 15.312 2.71289 14.496L5.73689 13.6799Z"
                fill="#12AFB8"/>
            <path
                d="M8.76103 6.38393C9.24103 6.09599 9.76897 5.87997 10.321 5.736L9.50514 2.71204C8.6891 2.92806 7.92117 3.2641 7.20117 3.67204L8.76103 6.38393Z"
                fill="#12AFB8"/>
            <path
                d="M7.41588 7.41599C7.00794 7.82392 6.67191 8.25599 6.38381 8.75995L3.67188 7.19995C4.10394 6.47995 4.60791 5.80789 5.20791 5.20789L7.41588 7.41599Z"
                fill="#12AFB8"/>
            <path
                d="M2.47232 10.752C2.52026 10.32 2.61629 9.91204 2.71232 9.52808L5.73628 10.32C5.66423 10.584 5.61628 10.8721 5.56834 11.16C5.52039 11.448 5.52039 11.736 5.52039 12H2.40039C2.40026 11.5921 2.42436 11.16 2.47231 10.7521L2.47232 10.752Z"
                fill="#12AFB8"/>
            <path
                d="M15.2647 9.16805C15.2408 9.14408 15.1927 9.1201 15.1687 9.09599C15.1208 9.07202 15.0966 9.07202 15.0487 9.07202C15.0008 9.07202 14.9527 9.07202 14.9287 9.09599C14.8808 9.11997 14.8566 9.14394 14.8327 9.16805L10.6807 13.248C10.6086 13.296 10.5367 13.3441 10.4407 13.3441C10.3446 13.3441 10.2727 13.3201 10.2007 13.248L9.16874 12.2401C9.09669 12.1922 9.02477 12.1441 8.92874 12.1441C8.83272 12.1441 8.7608 12.1681 8.68874 12.2401L8.06478 12.8641C8.01683 12.9361 7.96875 13.0081 7.96875 13.1041C7.96875 13.2001 7.99272 13.272 8.06478 13.3441L10.1768 15.432C10.2489 15.48 10.3208 15.528 10.4168 15.528C10.5129 15.528 10.5848 15.5041 10.6568 15.432L15.9128 10.248C15.9368 10.224 15.9608 10.176 15.9849 10.152C16.0089 10.104 16.0089 10.0799 16.0089 10.032C16.0089 9.98404 16.0089 9.93596 15.9849 9.91199C15.9609 9.86404 15.937 9.83993 15.9128 9.81596L15.2647 9.16805Z"
                fill="#12AFB8"/>
        </svg>
    );
};

export default RetestReadyIcon;
