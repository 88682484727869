import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeCTEMKeyInsightsModal } from '../../../../../redux/reducers/dashboard';
import Modal from '../../../../../components/MuiComponents/Modal';
import Typography from '../../../../../components/Typography';
import { useCTEMInsightDetails } from '../../../../../hooks/ctem';
import reactStringReplace from 'react-string-replace';
import Insight1 from './insightsContent/Insight1';
import Insight2 from './insightsContent/Insight2';
import Insight3 from './insightsContent/Insight3';
import Insight4 from './insightsContent/Insight4';
import Insight5 from './insightsContent/Insight5';
import MainLoader from '../../../../../components/Loader/MainLoader';

const CTEMKeyInsightModal = () => {
    const { ctemKeyInsightsModal: { insightId, title, description, companyId, count } } = useSelector(state => state.dashboard);
    const { data, isFetching } = useCTEMInsightDetails(companyId, insightId);
    const insight = data && JSON.parse(data?.insight);

    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeCTEMKeyInsightsModal())
    }

    return (
        <Modal
            open={true}
            maxWidth={"lg"}
            fullWidth={true}
            dialogTitle={<>
               <Typography variant={"h5"} className={`!text-white`}>
                    <span>{reactStringReplace(title, '{{COUNT}}', (match, i) => (
                            <span>{count}</span>
                        ))}</span>
                </Typography>
            </>}
            zeroPadding={true}
            onClose={closeModal}
        >
            <div className="text-left">
                {(isFetching) && <MainLoader/>}
                <div className="ctem-insight-container py-5">
                    <div className={"max-h-[500px] px-5 overflow-auto"}>
                        <div className="flex flex-[100%]">
                            {
                                insightId === '1001' ?
                                    <Insight1 
                                        description={description}
                                        data={insight}
                                    />
                                :
                                    insightId === '1002' ?
                                        <Insight2
                                            description={description}
                                            data={insight}
                                        />
                                    :
                                        insightId === '1003' ?
                                            <Insight3 
                                                description={description}
                                                data={insight}
                                            />
                                        :
                                            insightId === '1004' ?
                                                <Insight4 
                                                    description={description}
                                                    data={insight}
                                                />
                                            :
                                                insightId === '1005' ?
                                                    <Insight5 
                                                        description={description}
                                                        data={insight}
                                                    />
                                                :
                                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CTEMKeyInsightModal;
