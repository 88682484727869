import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from "@mui/material";
import { styled } from '@mui/system';

const TooltipContent = styled('div')({
    maxHeight: '300px',   // Set the maximum height for the tooltip
    minHeight: '10px',    // Set the minimum height for the tooltip
    overflowY: 'auto',    // Enable vertical scrolling when content exceeds maxHeight
    paddingTop: '10px',     // Optional: Adjust padding as needed
    marginRight: '-10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    marginTop: '-10px',
    marginBottom: '-10px',
    whiteSpace: 'pre-line', // Preserve line breaks if the title is a string
});

const MuiTooltip = ({children, ...props}) => {
    const {title} = props
    return (
        <Tooltip
            arrow={true}
            // open={true}
            placement={"top-end"}
            {...props}
            title={
                <TooltipContent>
                    <div className="">{title}</div>
                </TooltipContent>
            }
        >
            {children}
        </Tooltip>
    );
};

MuiTooltip.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

export default MuiTooltip;
