import axios from "axios";

export const getImage = async ({queryKey}) => {
    let key = queryKey[1]
    let type = queryKey[2]
    if (key) {
        let res = await axios.get(`/misc/s3/get_presigned_url?key=${encodeURIComponent(key)}&type=${type}`)
        return res.data?.signedUrl;
    }
}

export const getTemplate = async ({ queryKey }) => {
    let key = queryKey[0]
    let type = queryKey[1]
    if (key) {
        let res = await axios.get(`/misc/s3/get_presigned_url?key=${key}&type=${type}`)
        return res.data?.signedUrl;
    }
}
