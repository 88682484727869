import React, {memo} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles.module.scss'

const ErrorIcon = props => {
    return (
        <CloseIcon className={styles.error}/>
    );
};

export default memo(ErrorIcon);
