import React, { useState } from 'react';
import styles from "../../Admin/Dashboard/dashboard.module.scss";
import Typography from "../../../../components/Typography";
import { get2DigitFormattedNumber } from "../../../../actions/commonActions";
import { useWelcomeBannerAssetManagement } from '../../../../hooks/dashboard';
import pluralize from "pluralize";
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../../components/Button';
import { useDispatch } from 'react-redux';
import { setAddNewAssetsModal, setImportAssetsModal } from '../../../../redux/reducers/dashboard';
import { ImportAssets } from '../../../../components/Header/Menu';

const Status = (props) => {
    //console.log("props", props);
    const [importButtonText, setImportButtonText] = useState(false);
    const [assetButtonText, setAssetButtonText] = useState(false);
    
    const { data: bannerDetails } = useWelcomeBannerAssetManagement({ company_id: props?.company.id });
    //console.log("bannerDetails", bannerDetails)
    const assetCount = bannerDetails && bannerDetails.asset_count ? bannerDetails.asset_count : 0;

    const dispatch = useDispatch()
   
    return (
        <div className={`${styles.overview} h-full`}>
            <div className={styles.background}></div>
            <div className={"flex"}>
                <div className={"flex-1"}>
                {props.company.id ? 
                    <Typography variant={"h5"}>{props?.company.label} has a total of <span
                        className={"text-secondary-900 font-bold"}>{get2DigitFormattedNumber(assetCount)} {pluralize('Asset', assetCount)}</span></Typography>
                         : <Typography variant={"h5"}>Select a Company</Typography>}
                </div>
                <div className={`mx-2`}>
                    <ImportAssets className={`text-secondary-900`} companyId={props?.company?.id} />
                </div> 
                
                <div className={`mx-2`}>
                <Button onClick={() => dispatch(setAddNewAssetsModal({
                        open: true,
                        company_id: props?.company.id
                    }))} fullWidth={true} size={"small"} variant={"contained"}
             className={`!bg-secondary-700`}><AddIcon /> {assetButtonText ? 'Coming soon' : 'Add Asset'}</Button>
                </div>
                <div>
                </div>
            </div>
        </div>
    );
};

export default Status;
